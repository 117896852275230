import { useState } from 'react';

const useFilter = <T>(): [
  T[],
  (e: unknown, value: T[]) => void,
  () => void,
  (value: T) => () => void,
] => {
  const [filterValue, setFilterValue] = useState<T[]>([]);
  const handleChangeFilter = (e: unknown, value: T[]) => {
    setFilterValue(value);
  };
  const resetFilter = (): void => {
    setFilterValue([]);
  };
  const removeValue = (value: T) => () => {
    setFilterValue(filterValue.filter(v => v !== value));
  };
  return [filterValue, handleChangeFilter, resetFilter, removeValue];
};

export { useFilter };
