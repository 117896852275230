import { Icon } from '.';
import { IconProps } from './settings';

export const PersonIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_545_168" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.038 14.5539C18.9945 14.5539 22.2019 11.3465 22.2019 7.38999C22.2019 3.43347 18.9945 0.226074 15.038 0.226074C11.0815 0.226074 7.87408 3.43347 7.87408 7.38999C7.87408 11.3465 11.0815 14.5539 15.038 14.5539ZM15 15.1762C8.7868 15.1762 3.75 20.213 3.75 26.4262V29.5041H26.25V26.4262C26.25 20.213 21.2132 15.1762 15 15.1762Z"
          />
        </mask>
        <path
          d="M3.75 29.5041H1.75V31.5041H3.75V29.5041ZM26.25 29.5041V31.5041H28.25V29.5041H26.25ZM20.2019 7.38999C20.2019 10.2419 17.8899 12.5539 15.038 12.5539V16.5539C20.0991 16.5539 24.2019 12.4511 24.2019 7.38999H20.2019ZM15.038 2.22607C17.8899 2.22607 20.2019 4.53804 20.2019 7.38999H24.2019C24.2019 2.3289 20.0991 -1.77393 15.038 -1.77393V2.22607ZM9.87408 7.38999C9.87408 4.53804 12.186 2.22607 15.038 2.22607V-1.77393C9.9769 -1.77393 5.87408 2.3289 5.87408 7.38999H9.87408ZM15.038 12.5539C12.186 12.5539 9.87408 10.2419 9.87408 7.38999H5.87408C5.87408 12.4511 9.9769 16.5539 15.038 16.5539V12.5539ZM5.75 26.4262C5.75 21.3176 9.89137 17.1762 15 17.1762V13.1762C7.68223 13.1762 1.75 19.1085 1.75 26.4262H5.75ZM5.75 29.5041V26.4262H1.75V29.5041H5.75ZM26.25 27.5041H3.75V31.5041H26.25V27.5041ZM24.25 26.4262V29.5041H28.25V26.4262H24.25ZM15 17.1762C20.1086 17.1762 24.25 21.3176 24.25 26.4262H28.25C28.25 19.1085 22.3178 13.1762 15 13.1762V17.1762Z"
          fill="currentColor"
          mask="url(#path-1-inside-1_545_168)"
        />
      </svg>
    </Icon>
  );
};
