import React from 'react';

import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { Person } from 'models/oav/Person.models';
import { IdentityViewCard } from 'pages/person/{uuid}/IdentityViewCard';
import { ContactInfoViewCard } from 'pages/person/{uuid}/ContactInfoViewCard';

interface PersonViewProps {
  person: Person;
  onUpdate: () => void;
}

const Buttons = (props: { onUpdate: () => void }) => (
  <Grid item xs={12}>
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      useFlexGap
      spacing={2}
      justifyContent="flex-end"
      sx={{ mt: 2 }}
    >
      <Button size="medium" onClick={props.onUpdate} sx={{ px: 2 }}>
        Mettre à jour
      </Button>
    </Stack>
  </Grid>
);

export const PersonView = (props: PersonViewProps) => (
  <Box
    display="flex"
    justifyContent="center"
    sx={{ pt: 4, width: '100%', maxWidth: 1200, marginX: 'auto' }}
  >
    <Stack direction="column" justifyContent="center" spacing={2}>
      <IdentityViewCard person={props.person} />
      <ContactInfoViewCard person={props.person} />
      <Buttons onUpdate={props.onUpdate} />
    </Stack>
  </Box>
);
