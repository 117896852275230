import React, { useCallback } from 'react';

import {
  DropEvent,
  DropzoneOptions,
  ErrorCode,
  FileRejection,
  useDropzone,
} from 'react-dropzone';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';

import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';
import { UploadIcon } from 'components/Icon/UploadIcon';

export type DropzoneDocumentProps = {
  nbFiles: number;
  label: React.ReactNode;
  helper?: string;
} & DropzoneOptions;

export const DropzoneDocument: React.FC<DropzoneDocumentProps> = ({
  nbFiles,
  maxFiles: maxFilesProps,
  onDrop,
  label,
  helper,
  ...options
}) => {
  const { addSnackbar } = useAppContext();
  const theme = useTheme();

  const maxFiles = maxFilesProps !== undefined ? maxFilesProps : 1;

  const handleDrop = useCallback(
    (
      acceptedFiles: File[],
      rejectedFiles: FileRejection[],
      event: DropEvent,
    ) => {
      if (nbFiles == maxFiles) {
        if (rejectedFiles.length == 0) {
          addSnackbar(
            getDefaultErrorSnackBar('Nombre de fichier maximum déjà atteint.'),
          );
        }
        return;
      }
      onDrop?.(acceptedFiles, rejectedFiles, event);
    },
    [nbFiles, maxFiles, onDrop],
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorType = fileRejections[0]?.errors[0].code;
    switch (errorType) {
      case ErrorCode.FileInvalidType:
        addSnackbar(getDefaultErrorSnackBar('Format de fichier invalide.'));
        break;
      case ErrorCode.TooManyFiles:
        addSnackbar(
          getDefaultErrorSnackBar('Nombre de fichier maximum déjà atteint.'),
        );
        break;
      case ErrorCode.FileTooLarge:
        addSnackbar(getDefaultErrorSnackBar('Fichier trop volumineux.'));
        break;
      default:
        addSnackbar(
          getDefaultErrorSnackBar("Impossible d'ajouter le fichier."),
        );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: maxFiles - nbFiles,
    noClick: true,
    noKeyboard: true,
    onDropRejected,
    onDrop: handleDrop,
    maxSize: 10000000,
    accept: {
      'application/pdf': ['.pdf'],
      'image/bmp': ['.bmp'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
    },
    ...options,
  });

  return (
    <Card
      {...getRootProps({
        sx: {
          width: '100%',
          height: '100%',
          padding: 1,
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: isDragActive
            ? 'rgba(250, 250, 250, 0.04)'
            : theme.palette.background.default,
          backgroundImage:
            "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='rgb(175, 182, 200)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
          borderRadius: '24px',
        },
      })}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <UploadIcon />
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {label}
        </Typography>
        <Typography variant="body1">ou</Typography>
        <Button
          variant="text"
          component="label"
          disabled={maxFiles <= nbFiles}
          sx={{
            backgroundColor: theme.palette.text.primary,
            mt: 1,
            ':hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.8),
            },
          }}
          color="dark"
        >
          Importez
          <input
            {...getInputProps({
              type: 'file',
              disabled: maxFiles <= nbFiles,
              hidden: true,
            })}
          />
        </Button>
        {helper && (
          <Typography sx={{ mt: 1, textAlign: 'center' }} variant="caption">
            {helper}
          </Typography>
        )}
      </Stack>
    </Card>
  );
};
