import { ProjectStep, projectStepsData } from 'models/oav/ProjectStep.models';

export enum ProjectPhase {
  STUDY = 'STUDY',
  QUOTE = 'QUOTE',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export type ProjectPhaseData = {
  label: string;
};

export const projectPhasesData: Record<ProjectPhase, ProjectPhaseData> = {
  [ProjectPhase.STUDY]: {
    label: 'Étude',
  },
  [ProjectPhase.SUBSCRIPTION]: {
    label: 'Souscription',
  },
  [ProjectPhase.QUOTE]: {
    label: 'Devis',
  },
};

export const getProjectPhaseAsEntries = (): [
  ProjectPhase,
  ProjectPhaseData,
][] => {
  return Object.entries(projectPhasesData) as [
    ProjectPhase,
    ProjectPhaseData,
  ][];
};

export const getProjectPhaseAndAssociatedStep = (): Partial<
  Record<ProjectPhase, ProjectStep[]>
> => {
  const projectPhaseAndAssociatedStep: Partial<
    Record<ProjectPhase, ProjectStep[]>
  > = {};

  (Object.keys(ProjectStep) as ProjectStep[]).map(projectStep => {
    const projectPhase = projectStepsData[projectStep];
    if (projectPhase == null) {
      return;
    }

    if (projectPhaseAndAssociatedStep[projectPhase.phase] != null) {
      projectPhaseAndAssociatedStep[projectPhase.phase]?.push(projectStep);
    } else {
      projectPhaseAndAssociatedStep[projectPhase.phase] = [projectStep];
    }
  });

  return projectPhaseAndAssociatedStep;
};

export const getStepsByPhases = (
  projectPhases: ProjectPhase[],
): ProjectStep[] => {
  const projectPhaseAndAssociatedStep = getProjectPhaseAndAssociatedStep();

  return projectPhases.flatMap(
    projectPhase => projectPhaseAndAssociatedStep[projectPhase] ?? [],
  );
};
