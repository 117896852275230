import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { MutationGenerator, useAccessToken } from 'utils/api/api';
import {
  deleteSupportingDocument,
  fetchSupportingDocument,
  fetchSupportingDocuments,
  uploadSupportingDocument,
} from 'api/oav/SupportingDocument.api';
import {
  SupportingDocument,
  UploadSupportingDocumentRequest,
} from 'models/oav/SupportingDocument.models';

const defaultStaleTime = 30 * 1000;

const getOptions = (projectId: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'supporting-documents'],
    queryFn: () => fetchSupportingDocuments(token, projectId),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

const getByIdOptions = (projectId: string, supportingDocumentId: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: [
      'projects',
      projectId,
      'supporting-documents',
      supportingDocumentId,
    ],
    queryFn: () =>
      fetchSupportingDocument(token, projectId, supportingDocumentId),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

const useDelete: MutationGenerator<
  void,
  {
    projectId: string;
    supportingDocumentId: string;
  }
> = ({ onSuccess, onError }) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, supportingDocumentId }) =>
      deleteSupportingDocument(accessToken, projectId, supportingDocumentId),
    onSuccess: (data, variables, context) => {
      client
        .invalidateQueries({
          queryKey: ['projects', variables.projectId, 'supporting-documents'],
        })
        .then()
        .catch();
      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

const useUpload: MutationGenerator<
  SupportingDocument,
  {
    projectId: string;
    file: File;
    payload: UploadSupportingDocumentRequest;
  }
> = ({ onSuccess, onError }) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, file, payload }) =>
      uploadSupportingDocument(accessToken, projectId, file, payload),
    onSuccess: (data, variables, context) => {
      client.invalidateQueries({
        queryKey: ['projects', variables.projectId],
      });
      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

export const supportingDocumentsQueries = {
  get: getOptions,
  getById: getByIdOptions,
};

export const supportingDocumentMutations = {
  useDelete,
  useUpload,
};
