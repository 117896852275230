import { NavigateFunction } from 'react-router/dist/lib/hooks';
import {
  getNextStep,
  getPreviousStep,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { Project } from 'models/oav/Project.models';
import { FormulasSelectionMap } from 'components/Context/ProjectContext';
import { Beneficiary, BeneficiaryType } from 'models/oav/Beneficiary.models';

export const navigateToNextStep = (
  currentStep: ProjectStep,
  projectId: string,
  navigate: NavigateFunction,
) => {
  const nextStep = getNextStep(currentStep);
  if (nextStep)
    navigate(`/projets/${projectId}/${projectStepsData[nextStep]!.path}`);
};

export const navigateToPreviousStep = (
  currentStep: ProjectStep,
  projectId: string,
  navigate: NavigateFunction,
) => {
  const nextStep = getPreviousStep(currentStep);
  if (nextStep)
    navigate(`/projets/${projectId}/${projectStepsData[nextStep]!.path}`);
};

export const projectToFormulasSelectionMap = (project?: Project) =>
  project?.subscriptions?.reduce<FormulasSelectionMap>(
    (previousValue, currentValue) => {
      previousValue[currentValue.id] =
        currentValue.shoppingCartItems?.map(item => item.formulaCode) || [];
      return previousValue;
    },
    {},
  ) || {};

export const getBeneficiariesLinkedToSubscription = (
  project: Project,
): { partner?: Beneficiary; children: Beneficiary[] } => {
  let partner: Beneficiary | undefined = undefined;
  const children: { [key: string]: Beneficiary } = {};

  project?.subscriptions?.forEach(s =>
    s.beneficiaries?.forEach(_ => {
      if (_.type == BeneficiaryType.CHILD) {
        children[_.id!] = _;
      } else if (_.type == BeneficiaryType.PARTNER) {
        partner = _;
      }
    }),
  );

  return {
    partner,
    children: Object.entries(children).map(([, _]) => _),
  };
};
