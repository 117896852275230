import { Icon } from '.';
import { IconProps } from './settings';

export const Disconnect = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.5386 2.88477V17.8385"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M7.61538 6.73828C4.80596 8.89466 3 12.2531 3 16.0256C3 22.5325 8.37258 27.8074 15 27.8074C21.6274 27.8074 27 22.5325 27 16.0256C27 12.2531 25.194 8.89466 22.3846 6.73828"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Icon>
  );
};
