import React from 'react';
import dayjs from 'dayjs';
import { getIn, useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';

import { FormClientsInfoValues } from 'pages/project/{uuid}/ProjectClientsInformationPage';
import { Beneficiary } from 'models/oav/Beneficiary.models';
import TextField from 'components/TextField/TextField';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { Civility, civilityLabels } from 'models/oav/Person.models';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import {
  getErrorField,
  getHelperTextField,
  getRegime,
} from 'utils/fields/fields';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';

interface FormClientInfoProps {
  beneficiary: Beneficiary;
  name: string;
}

export const FormClientInfo = (props: FormClientInfoProps) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } =
    useFormikContext<FormClientsInfoValues>();
  const firstnameField = `${props.name}.firstname`;
  const lastnameField = `${props.name}.lastname`;
  const civilityField = `${props.name}.civility`;

  const regime = getRegime(props.beneficiary.person.regimeCode);

  return (
    <Grid
      container
      spacing={{
        sm: 2,
        xs: 1,
      }}
    >
      <Grid item xs={12}>
        <ToggleButtonGroupSplit
          value={getIn(values, civilityField)}
          exclusive
          onChange={(e, v) => setFieldValue(civilityField, v)}
        >
          {Object.entries(Civility).map(([key]) => (
            <ToggleButtonSplit key={key} fullWidth value={key} color="primary">
              {civilityLabels[key as Civility].label}
            </ToggleButtonSplit>
          ))}
        </ToggleButtonGroupSplit>
        {getIn(touched, civilityField) && getIn(errors, civilityField) && (
          <FormHelperText error>{getIn(errors, civilityField)}</FormHelperText>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          required
          id={lastnameField}
          name={lastnameField}
          label="Nom"
          value={getIn(values, lastnameField)}
          error={getErrorField(errors, touched, lastnameField)}
          helperText={getHelperTextField(errors, touched, lastnameField)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          required
          id={firstnameField}
          name={firstnameField}
          label="Prénom"
          value={getIn(values, firstnameField)}
          error={getErrorField(errors, touched, firstnameField)}
          helperText={getHelperTextField(errors, touched, firstnameField)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Date de naissance"
          value={dayjs(props.beneficiary.person.birthdate).format('DD/MM/YYYY')}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly label="Régime social" value={regime?.label || ''} />
      </Grid>
    </Grid>
  );
};
