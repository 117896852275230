import { Icon } from '.';
import { IconProps } from './settings';

export const DownloadIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 12V28M15 28L11 23.1351M15 28L19 23.1351"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.1795 16.9857L7.33873 17H7.5H12V19H9H7.5H7V18.9776C4.19675 18.725 2 16.369 2 13.5C2 11.0308 3.62717 8.9416 5.86773 8.24626C6.12472 4.75926 9.46844 2 13.5556 2C17.1415 2 20.1551 4.12402 21.0139 7.00001C24.8735 7.00644 28 9.69027 28 13C28 16.3137 24.866 19 21 19H18V17H21C24.0618 17 26 14.931 26 13C26 11.0712 24.0667 9.0051 21.0106 9.00001L19.5231 8.99753L19.0975 7.57224C18.5314 5.67629 16.4042 4 13.5556 4C10.2752 4 8.0258 6.17509 7.86232 8.39325L7.76216 9.75243L6.46053 10.1564C5.033 10.5994 4 11.9317 4 13.5C4 15.3246 5.39773 16.8251 7.1795 16.9857ZM16 17H14V19H16V17Z"
          fill={props.color}
        />
      </svg>
    </Icon>
  );
};
