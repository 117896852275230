import { PaletteOptions } from '@mui/material/styles';
import { Disabled } from 'components/TextField/TextField/stories';

export const paletteLight: PaletteOptions = {
  primary: {
    main: '#7992EA',
    contrastText: '#FAFAFA',
  },
  secondary: {
    main: '#F89136',
    contrastText: '#FAFAFA',
  },
  error: {
    main: '#F23A3A',
    contrastText: '#FAFAFA',
  },
  warning: {
    main: '#F89136',
    contrastText: '#FAFAFA',
  },
  info: {
    main: '#2155CD',
    contrastText: '#FAFAFA',
  },
  success: {
    main: '#52E09C',
    contrastText: '#FAFAFA',
  },
  text: {
    primary: '#303030',
    secondary: '#58595D',
    disabled: '#909093',
  },
  background: {
    default: '#ECF1FC',
    paper: '#FFFFFF',
  },
  action: {
    disabled: '#909093',
    disabledBackground: '#929596',
  },
  divider: '#e7e6f7',
};

export const opacityLight = {
  card: 0.2,
  chip: 0.2,
  disabled: 0.05,
  field: 0.05,
  focused: 0.05,
  hover: 0.15,
  pre: 0.1,
  scrollbarThumb: 0.8,
  tooltip: 0.05,
};
