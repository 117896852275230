import { Link } from 'react-router-dom';

import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import { useTheme } from '@mui/material/styles';
import { UserInfo } from 'model/UserInfo.model';

export type AvatarUserProps = Partial<AvatarProps> & {
  user?: UserInfo;
  minified?: boolean;
  selected?: boolean;
};

const AvatarUser = (props: AvatarUserProps) => {
  const theme = useTheme();

  return (
    <Link to="compte">
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          pb: props.user?.title && !props.minified ? 2 : 0,
        }}
      >
        <Tooltip
          title={!props.minified ? '' : 'Votre profil'}
          placement="right"
        >
          <Avatar
            alt={props.user?.name}
            src={props.user?.pictureUri}
            sx={{
              border: props.selected && props.minified ? 2 : 0,
              borderColor: theme.palette.primary.main,
              width: props.minified ? 40 : 64,
              height: props.minified ? 40 : 64,
            }}
          />
        </Tooltip>

        {props.user?.title && !props.minified && (
          <Chip
            label={props.user?.title}
            color="primary"
            component="div"
            sx={{
              position: 'absolute',
              bottom: 2,
              fontWeight: 700,
              height: 'auto',
              py: 0.5,
            }}
          />
        )}
      </Box>
    </Link>
  );
};

export default AvatarUser;
