import { Risk } from 'models/referentiels/Risk.model';
import { WrongStatusError } from './index';

const url = `${process.env.REACT_APP_API_URI_BASE}/referentiels/risk`;

const fetchRisks = async (accessToken: string): Promise<Risk[]> => {
  const response = await fetch(
    url +
      '?' +
      new URLSearchParams({
        sort: '+label',
      }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }
  return await response.json();
};

export { fetchRisks };
