import React, { ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Divider, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export type ItemProps = {
  label: string;
  value?: string | ReactElement;
  sx?: SxProps<Theme> | undefined;
};

export const FieldReadOnly = (props: ItemProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems={'stretch'}
      sx={{
        minHeight: '4rem',
        ...props.sx,
      }}
    >
      <Typography variant="body2">{props.label}</Typography>
      <Typography variant="body1">{props.value} </Typography>
      <Divider sx={{ my: 1 }} />
    </Stack>
  );
};
