import { Beneficiary } from './Beneficiary.models';
import { BankDetails } from 'models/oav/BankDetails.models';

export type Subscription = {
  id: string;
  offerCode: string;
  formulaCode?: string;
  recommendedFormulaCode?: string;
  dateStart: Date;
  beneficiaries?: Beneficiary[];
  shoppingCartItems?: ShoppingCartItem[];
  creditBankDetails?: BankDetails;
  debitBankDetails?: BankDetails;
  paymentDay?: PaymentDay;
  paymentFrequency?: PaymentFrequency;
};

export type SubscriptionRequest = {
  id?: string;
  offerCode: string;
  formulaCode?: string;
  dateStart: Date;
  beneficiariesId?: string[];
};

export type SubscriptionRequestErrors = {
  offerCode?: string;
  formulaCode?: string;
  dateStart?: string;
  [key: string]: string | undefined;
};

export type ShoppingCartItem = {
  id: string;
  formulaCode: string;
};

export enum PaymentDay {
  FIFTH = 5,
  TENTH = 10,
  FIFTEENTH = 15,
}

export enum PaymentFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  BI_ANNUALLY = 'BI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export const paymentFrequencyLabels: {
  [key in PaymentFrequency]: { label: string; order: number };
} = {
  [PaymentFrequency.MONTHLY]: { label: 'Mensuelle', order: 0 },
  [PaymentFrequency.QUARTERLY]: { label: 'Trimestrielle', order: 1 },
  [PaymentFrequency.BI_ANNUALLY]: { label: 'Semestrielle', order: 2 },
  [PaymentFrequency.ANNUALLY]: { label: 'Annuelle', order: 3 },
};

export interface BankDetailsRequest {
  paymentDay: PaymentDay;
  paymentFrequency: PaymentFrequency;
  creditBankDetails?: Omit<BankDetails, 'id'>;
  debitBankDetails: Omit<BankDetails, 'id'>;
}

export interface BankDetailsRequestErrors {
  paymentDay?: string;
  paymentFrequency?: string;
  creditBankDetails?: {
    iban?: string;
    bic?: string;
    accountHolder?: string;
  };
  debitBankDetails?: {
    iban?: string;
    bic?: string;
    accountHolder?: string;
  };
}
