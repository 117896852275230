import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import dayjs from 'dayjs';

import { Formik, Form, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import * as Yup from 'yup';

import { darken, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import CardOffer from 'components/Card/CardOffer';
import { useProjectContext } from 'components/Context/ProjectContext';

import { Offer } from 'models/referentiels/Offer.model';
import { Project } from 'models/oav/Project.models';
import {
  ProjectStepSubscriptions,
  ProjectStepSubscriptionsErrors,
} from 'models/oav/ProjectStep.models';
import { updateProjectStepSubscriptions } from 'api/oav/ProjectStep.api';
import { Subscription } from 'models/oav/Subscription.models';
import { Segment } from 'models/referentiels/Segment';
import { isBadRequestWithDetailsError } from 'utils/api/api';

export interface FormOfferSelectValues {
  subscriptions?: Subscription[];
}

export interface FormOfferSelectsErrors {
  subscriptions?: string;
}

export interface FormOfferSelectRef {
  resetForm: () => void;
  validateForm: () => Promise<any>;
  submitForm: () => Promise<Project>;
  values: FormOfferSelectValues;
}

export interface FormOfferSelectProps {
  project: Project;
  options: Offer[];
  initialValues?: FormOfferSelectValues;
  errors?: FormikErrors<FormOfferSelectValues>;
  maxOffers?: number;
  onChangeValues?: (_: FormOfferSelectValues) => void;
}

const FormOfferSelect = forwardRef((props: FormOfferSelectProps, ref: any) => {
  const theme = useTheme();
  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));
  const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));

  const { dataCurrent } = useProjectContext();

  // ForwardRef methods.
  useImperativeHandle(ref, () => ({
    resetForm: () => {
      formikRef.current!.resetForm();
    },
    validateForm: () => {
      formikRef.current!.setTouched({
        subscriptions: true,
      });
      return formikRef.current!.validateForm();
    },
    submitForm: () => {
      return submit();
    },
    get values() {
      return formikRef.current!.values;
    },
  }));

  const auth = useAuth();

  // Form.
  const formikRef = useRef<FormikProps<FormOfferSelectValues>>(null);
  const alertOfferUnselect: boolean = false;
  const [offerUnselected, setOfferUnselected] = useState<any>();
  const [offerSelected, setOfferSelected] = useState<any>();

  const validationSchema = Yup.object().shape({
    subscriptions: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          offerCode: Yup.string().required('Offre non valide'),
        }),
      )
      .test({
        message:
          props.maxOffers !== undefined && props.maxOffers === 1
            ? 'Vous devez sélectionner une offre'
            : 'Sélectionnez au moins une offre',
        test: arr => arr && arr?.length !== 0,
      })
      .test({
        message: `Vous ne pouvez pas sélectionner plus ${props.maxOffers === 1 ? "d'une offre" : `de  ${props.maxOffers} offres`}`,
        test: arr =>
          props.maxOffers === undefined ||
          (arr && arr?.length <= props.maxOffers),
      }),
  });

  // Main form
  const submit = async () => {
    const errors = await formikRef.current!.validateForm();
    const values = formikRef.current?.values;

    if (Object.keys(errors).length !== 0) return;

    // Build DTO.
    const payload: ProjectStepSubscriptions = {
      subscriptions:
        values?.subscriptions?.map(_ => ({
          id: _.id,
          offerCode: _.offerCode,
          dateStart: dayjs().add(1, 'day').startOf('day').toDate(),
        })) || [],
    };

    return updateProjectStepSubscriptions(
      auth.user!.access_token,
      props.project.id,
      payload,
    ).catch(e => {
      if (isBadRequestWithDetailsError<ProjectStepSubscriptionsErrors>(e)) {
        const errors = e.response?.details;
        if (errors) onBadRequest(errors);
      } else {
        // TODO : Snackbar
      }
    });
  };

  const onBadRequest = (errors: ProjectStepSubscriptionsErrors) => {
    if (errors.subscriptions) {
      formikRef.current?.setFieldError('subscriptions', errors.subscriptions);
    } else {
      const subscriptionsErrors = formikRef.current?.values.subscriptions?.map(
        (_, idx) => errors[`subscriptions[${idx}]`],
      );
      formikRef.current?.setFieldError(
        'subscriptions',
        subscriptionsErrors as any,
      );
    }
  };

  const updateSelectOffer = (_: Offer) => {
    const values = formikRef.current?.values.subscriptions;
    const selected = values?.find(s => s.offerCode === _.code) !== undefined;

    if (!selected) {
      if (props.maxOffers === 1)
        formikRef.current?.setFieldValue('subscriptions', [
          { offerCode: _.code },
        ]);
      else
        formikRef.current?.setFieldValue('subscriptions', [
          ...(values || []),
          { offerCode: _.code },
        ]);
    } else {
      if (props.maxOffers === 1)
        formikRef.current?.setFieldValue('subscriptions', []);
      else
        formikRef.current?.setFieldValue(
          'subscriptions',
          values?.filter(s => s.offerCode !== _.code),
        );
    }
  };

  useEffect(() => {
    if (!props.errors) return;
    Object.entries(props.errors).forEach(_ => {
      formikRef.current!.setFieldTouched(_[0]);
      formikRef.current!.setFieldError(_[0], _[1]);
    });
  }, [props.errors]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={
        props.initialValues || {
          subscriptions: [],
        }
      }
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setSubmitting }: FormikHelpers<FormOfferSelectValues>,
      ) => {
        setSubmitting(false);
      }}
    >
      {({ errors, values }) => {
        errors = { ...errors, ...props.errors };

        useEffect(() => {
          if (Object.keys(values).length > 0 && props.onChangeValues) {
            props.onChangeValues(values);
          }
        }, [values]);

        return (
          <Form>
            <Grid
              container
              spacing={{
                sm: 2,
                xs: 1,
              }}
            >
              <Grid item xs={12}>
                <Grid container alignItems="stretch" spacing={2}>
                  {props.options.length === 0 ? (
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          p: 2,
                          height: 200,
                        }}
                      >
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                          sx={{
                            height: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h3">🤷‍♀️ Zut !</Typography>
                          <Typography>
                            Aucune offre n'est éligible aux données de votre
                            projet.
                          </Typography>
                          <Typography variant="caption">
                            L'éligibilité d'une offre est basée sur l'
                            <pre style={{ display: 'inline-flex' }}>âge</pre>
                            et le{' '}
                            <pre style={{ display: 'inline-flex' }}>
                              régime
                            </pre>{' '}
                            de votre client.
                          </Typography>
                        </Stack>
                      </Card>
                    </Grid>
                  ) : (
                    props.options?.map(_ => {
                      const selected =
                        values.subscriptions?.find(
                          s => s.offerCode === _.code,
                        ) !== undefined;

                      const valueIndex = values.subscriptions?.findIndex(
                        v => v.offerCode === _.code,
                      );
                      const fieldError =
                        errors.subscriptions &&
                        valueIndex != undefined &&
                        valueIndex != -1
                          ? errors.subscriptions[valueIndex]
                          : undefined;
                      let error;
                      if (!fieldError) error = undefined;
                      else if (typeof fieldError === 'string')
                        error = fieldError;
                      else if (typeof fieldError === 'object')
                        error = Object.values(fieldError as Object).join(', ');

                      return (
                        <Grid key={_.code} item lg={4} sm={6} xs={12}>
                          <CardOffer
                            key={`card-${_.code}`}
                            offer={_}
                            selected={selected}
                            error={Boolean(error)}
                            helperText={error}
                            onClick={e => {
                              let dataCurrentOffer;
                              if (dataCurrent.project?.subscriptions) {
                                dataCurrentOffer =
                                  props.maxOffers === 1
                                    ? dataCurrent.project.subscriptions[0]
                                    : dataCurrent.project.subscriptions?.find(
                                        (o: any) => o.offerCode === _.code,
                                      );
                              }

                              if (
                                alertOfferUnselect &&
                                (props.maxOffers === 1 || selected) &&
                                dataCurrentOffer &&
                                dataCurrentOffer?.formulaCode
                              ) {
                                setOfferUnselected(dataCurrentOffer);
                                setOfferSelected(_);
                              } else updateSelectOffer(_);
                            }}
                          />
                        </Grid>
                      );
                    })
                  )}

                  {props.options &&
                    props.options.length > 0 &&
                    ((screenSizeUpLG && props.options?.length < 3) ||
                      (screenSizeUpSM && props.options?.length < 2)) && (
                      <Grid
                        item
                        lg={props.options.length === 1 ? 8 : 4}
                        sm={6}
                        xs={12}
                      >
                        <Card
                          sx={{
                            p: 2,
                            height: '100%',
                            backgroundColor: darken(
                              theme.palette.background.default,
                              0.05,
                            ),
                          }}
                        >
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                            sx={{
                              textAlign: 'center',
                              height: '100%',
                            }}
                          >
                            <Typography variant="h3">👌 Trouvé !</Typography>
                            <Typography>
                              <pre
                                style={{
                                  display: 'inline-flex',
                                  fontWeight: 700,
                                }}
                              >
                                {props.options?.length}
                              </pre>
                              &nbsp;
                              {props.options?.length === 1
                                ? 'offre est éligible pour votre projet !'
                                : props.options?.length === 2
                                  ? 'offres sont éligibles pour votre projet !'
                                  : ''}
                            </Typography>
                          </Stack>
                        </Card>
                      </Grid>
                    )}

                  {errors.subscriptions && (
                    <Grid item xs={12}>
                      <FormHelperText error>
                        {typeof errors.subscriptions === 'string'
                          ? errors.subscriptions
                          : 'Une erreur est survenue avec les offres sélectionnées'}
                      </FormHelperText>
                    </Grid>
                  )}

                  <Dialog
                    open={offerUnselected !== undefined}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>
                      <Typography variant="h2">
                        Supprimer une offre du projet ?
                      </Typography>
                    </DialogTitle>

                    {offerUnselected && (
                      <DialogContent>
                        <Alert
                          icon={false}
                          variant="outlined"
                          severity="warning"
                          sx={{
                            border: 2,
                            borderColor: theme.palette.warning.main,
                            backgroundColor: darken(
                              theme.palette.background.default,
                              0.15,
                            ),
                          }}
                        >
                          <Typography variant="body2">
                            Vous vous apprêtez à supprimer une offre du project.
                          </Typography>
                          <Typography>
                            Cette suppression entraînera la perte des données du
                            projet relatives à cette offre.
                          </Typography>
                        </Alert>

                        <Card
                          sx={{
                            backgroundColor: darken(
                              theme.palette.background.default,
                              0.15,
                            ),
                            mt: 2,
                            p: 2,
                          }}
                        >
                          <Stack justifyContent="space-between" spacing={1}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography variant="h5">
                                {offerUnselected.label}
                              </Typography>
                              {offerUnselected?.segments?.map((_: Segment) => (
                                <Chip key={_.code} label={_.label} />
                              ))}
                            </Stack>

                            <Box>
                              <Typography variant="caption">Formule</Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography
                                  sx={{
                                    color:
                                      offerUnselected?.formula.color || 'none',
                                  }}
                                >
                                  {offerUnselected?.formula.label}
                                </Typography>
                                <pre style={{ display: 'inline-flex' }}>
                                  {offerUnselected?.formula.pricing.monthly}{' '}
                                  €/mois
                                </pre>
                              </Stack>
                            </Box>

                            <Box>
                              <Typography variant="caption">Renfort</Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography>
                                  {offerUnselected?.renfort?.label || (
                                    <i>Aucun</i>
                                  )}
                                </Typography>
                                <pre style={{ display: 'inline-flex' }}>
                                  {offerUnselected?.renfort?.pricing?.monthly.toLocaleString(
                                    'fr-FR',
                                  ) || '-'}{' '}
                                  €/mois
                                </pre>
                              </Stack>
                            </Box>

                            <Box>
                              <Typography variant="caption">Options</Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography>
                                  {offerUnselected?.option?.label || (
                                    <i>Aucune</i>
                                  )}
                                </Typography>
                                <pre style={{ display: 'inline-flex' }}>
                                  {offerUnselected?.option?.pricing?.monthly ||
                                    '-'}{' '}
                                  €/mois
                                </pre>
                              </Stack>
                            </Box>
                          </Stack>
                        </Card>
                      </DialogContent>
                    )}
                    <DialogActions
                      sx={{
                        p: 2,
                      }}
                    >
                      <Grid container spacing={2} justifyContent="flex-end">
                        <Grid
                          order={{
                            sm: 0,
                            xs: 1,
                          }}
                          item
                          sm="auto"
                          xs={12}
                        >
                          <Button
                            fullWidth
                            variant="text"
                            color="default"
                            onClick={() => {
                              setOfferUnselected(undefined);
                              setOfferSelected(undefined);
                            }}
                          >
                            Annuler
                          </Button>
                        </Grid>
                        <Grid
                          order={{
                            sm: 1,
                            xs: 0,
                          }}
                          item
                          sm="auto"
                          xs={12}
                        >
                          <Button
                            fullWidth
                            color="error"
                            onClick={() => {
                              if (offerSelected)
                                updateSelectOffer(offerSelected);
                              setOfferUnselected(undefined);
                              setOfferSelected(undefined);
                            }}
                          >
                            Supprimer
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});

FormOfferSelect.displayName = 'FormOfferSelect';

export default memo(FormOfferSelect);
