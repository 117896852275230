import { useMemo, useRef } from 'react';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import FormBeneficiariesExpress, {
  FormBeneficiariesExpressRef,
  FormBeneficiariesExpressValues,
} from 'content/form/FormBeneficiariesExpress';
import { useProjectContext } from 'components/Context/ProjectContext';
import { BeneficiaryType } from 'models/oav/Beneficiary.models';

const ProjectBeneficiariesPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const refForm = useRef<FormBeneficiariesExpressRef>(null);

  const contextProject = useProjectContext();

  const getInitialValues = (): FormBeneficiariesExpressValues => {
    const values: FormBeneficiariesExpressValues = {};
    if (!contextProject.dataPersisted.project) return values;

    if (contextProject.dataPersisted.project.subscriber) {
      const _ = contextProject.dataPersisted.project.subscriber.person;
      values.subscriber = {
        type: BeneficiaryType.SUBSCRIBER,
        postalCode: _.address?.postalCode,
        birthDate: _.birthdate,
        regime: _.regimeCode,
      };
    }

    if (contextProject.dataPersisted.project.partner) {
      const _ = contextProject.dataPersisted.project.partner.person;
      values.partner = {
        type: BeneficiaryType.PARTNER,
        birthDate: _.birthdate,
        regime: _.regimeCode,
      };
    }

    if (
      contextProject.dataPersisted.project.children &&
      contextProject.dataPersisted.project.children.length > 0
    ) {
      values.children = [];
      for (const _ of contextProject.dataPersisted.project.children) {
        values.children.push({
          type: BeneficiaryType.CHILD,
          birthDate: _.person.birthdate,
          regime: _.person.regimeCode,
        });
      }
    }

    return values;
  };
  const initialValues = useMemo(getInitialValues, []);

  const submit = async () => {
    await refForm.current?.submitForm();
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Bénéficiaires
          <Chip
            sx={{
              ml: 1,
            }}
            label={
              (contextProject.dataCurrent.project?.children?.length || 0) +
              (contextProject.dataCurrent.project?.subscriber ? 1 : 0) +
              (contextProject.dataCurrent.project?.partner ? 1 : 0)
            }
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormBeneficiariesExpress
          ref={refForm}
          initialValues={initialValues}
          maxChildren={3}
          onChangeValues={_ => {
            contextProject.setDataCurrent(prev => {
              if (!prev.project) return prev;

              const subscriber = {
                ...prev.project.subscriber,
                person: {
                  ...prev.project.subscriber.person,
                  birthdate: _.subscriber?.birthDate as Date,
                  regimeCode: _.subscriber?.regime,
                },
              };

              const partner = _.partner && {
                ...prev.project.partner,
                type: BeneficiaryType.PARTNER,
                person: {
                  ...prev.project.partner?.person,
                  birthdate: _.partner?.birthDate as Date,
                  regimeCode: _.partner?.regime,
                },
              };

              const children =
                _.children &&
                [...(_.children || [])].map((c, index) => {
                  const prevChild = prev.project?.children && {
                    ...prev.project.children[index],
                  };
                  return {
                    prevChild,
                    type: BeneficiaryType.CHILD,
                    person: {
                      prevChild,
                      birthdate: c?.birthDate as Date,
                      regimeCode: c?.regime,
                    },
                  };
                });

              // Here only the express data are updated.
              return {
                ...prev,
                project: {
                  ...prev.project,
                  subscriber: subscriber,
                  partner: partner,
                  children: children,
                },
              };
            });
          }}
        />
      </Grid>
      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          onClick={() => {
            submit();
          }}
          sx={{ px: 4 }}
        >
          Valider
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectBeneficiariesPage;
