import React, { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SidebarContent from './content';

import { appName } from 'App';
import AvatarUser from '../Avatar/AvatarUser';
import ButtonLink from '../Button/ButtonLink';
import { useAppContext } from '../Context/AppContext';
import { useUserContext } from '../Context/UserContext';
import { ChevronCircleRightIcon } from '../Icon/ChevronCircleRightIcon';
import { GSMCIcon } from '../Icon/GSMCIcon';

const Sidebar: React.FC = () => {
  const theme = useTheme();
  const appContext = useAppContext();
  const userContext = useUserContext();

  const { pathname } = useLocation();

  const drawerWidth = 260;
  const drawerWidthMinified = 65;

  const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));
  const [showMinifyAction, setShowMinifyAction] = useState(false);

  const minified = !screenSizeUpLG || appContext.data.sidebarMinified;

  const toggleSidebar = () => {
    appContext.setData({
      ...appContext.data,
      sidebarMinified: !appContext.data.sidebarMinified,
    });
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      onMouseEnter={() => setShowMinifyAction(true)}
      onMouseLeave={() => setShowMinifyAction(false)}
    >
      <Drawer
        variant="permanent"
        sx={{
          display: {
            sm: 'flex',
            xs: 'none',
          },
          width: minified ? drawerWidthMinified : drawerWidth,
          '& .MuiDrawer-paper': {
            background: theme.palette.background.default,
            width: minified ? drawerWidthMinified : drawerWidth,
            overflow: 'hidden',
            transition: 'width 150ms ease-in-out',
            borderColor: theme.palette.divider,
            p: 1,
          },
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{
            alignItems: minified ? 'center' : 'stretch',
            pb: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{
              display: 'flex',
              pt: 1.5,
              pb: 0.5,
            }}
          >
            <GSMCIcon
              width="40px"
              height="100%"
              color={theme.palette.text.primary}
            />
            {!minified && (
              <Stack
                justifyContent="stretch"
                sx={{
                  textAlign: 'justify',
                  pt: 1,
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    letterSpacing: '0.175rem',
                    lineHeight: '1.25rem',
                    textTransform: 'uppercase',
                  }}
                >
                  {appName}
                </Typography>
                <Typography>Mutuelle GSMC</Typography>
              </Stack>
            )}
          </Stack>

          {minified && <Divider sx={{ width: '50%' }} />}

          {!minified && (
            <AvatarUser
              user={userContext.data.user}
              selected={matchPath('compte/*', pathname) !== null}
              minified={minified}
            />
          )}
        </Stack>
        <SidebarContent minified={minified} />
      </Drawer>

      <Box
        sx={{
          height: '100vh',
          position: 'absolute',
          right: showMinifyAction ? '-64px' : 0,
          display: {
            md: 'flex',
            xs: 'none',
          },
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'right 150ms ease-in-out',
          textAlign: minified ? 'center' : 'right',
          p: 2,
          zIndex: 1199,
        }}
      >
        <ButtonLink
          label={appContext.data.sidebarMinified ? 'Agrandir' : 'Réduire'}
          icon={
            <ChevronCircleRightIcon
              style={{
                transform: `rotate(${appContext.data.sidebarMinified ? 0 : 180}deg)`,
                transition: 'all 200ms ease-in-out',
              }}
            />
          }
          size="medium"
          onClick={() => toggleSidebar()}
          minified
        />
      </Box>
    </Box>
  );
};

export default Sidebar;
