import { Box, useTheme } from '@mui/material';

import ReactPhoneInput, {
  CountryData,
  PhoneInputProps,
} from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import TextField from '../TextField';

export type TextFieldPhoneProps = Partial<PhoneInputProps> & {
  error?: boolean;
  name?: string;
};

export const countryCodeEnabled = [
  'ad', // Andorra
  'be', // Belgium
  'ch', // Switzerland
  'es', // Spain
  'fr', // France
  'it', // Italy
  'lu', // Luxembourg
  'mc', // Monaco
];

const TextFieldPhone = (props: TextFieldPhoneProps) => {
  const theme = useTheme();

  return (
    <Box
      className="react-tel-input"
      sx={{
        '& .MuiInputLabel-root[data-shrink="true"]': {
          pl: 4,
        },
        '& input[type=tel]': {
          pl: 5,
        },
        '& .country-list': {
          position: 'fixed',
          borderRadius: 2,
          background: theme.palette.background.default,
        },
        '& .country-list .country .dial-code': {
          color: theme.palette.text.secondary,
          fontFamily: 'monospace',
          px: 1,
          m: 0,
          borderRadius: 1,
          backgroundColor: '#00000040',
        },
        '& .country-list .divider': {
          borderBottom: '1px solid #00000040',
        },
        '& .country-list .country:hover, & .country-list .country.highlight': {
          backgroundColor: '#00000020',
        },
        '& .selected-flag': {
          width: 32,
          height: 32,
          m: 1,
          mt: '9px',
          p: 1,
          ':hover': {
            backgroundColor: '#00000020',
            borderRadius: 4,
          },
        },
        '& .selected-flag .flag': {
          mt: '-5px',
        },
        '& .selected-flag .arrow': {
          border: 'none',
        },
      }}
    >
      <ReactPhoneInput
        {...props}
        onChange={(value, data, e, fv) => {
          if (!props.onChange) return;

          const c = data as CountryData;
          let v = value;
          if (!c) v = '';
          else if (!value.startsWith(c.dialCode)) v = c.dialCode;

          props.onChange(v, data, e, fv);
        }}
        inputProps={{
          name: props.name,
        }}
        component={TextField}
        placeholder={props.placeholder || '+33 X XX XX XX XX'}
        isValid={!props.error}
      />
    </Box>
  );
};

export default TextFieldPhone;
