import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MutationGenerator, useAccessToken } from 'utils/api/api';
import {
  Beneficiary,
  UpdateBeneficiariesRequest,
} from 'models/oav/Beneficiary.models';
import { updateBeneficiaries } from 'api/oav/Beneficiary.api';

const useUpdateBeneficiaries: MutationGenerator<
  Beneficiary[],
  {
    projectId: string;
    payload: UpdateBeneficiariesRequest;
  }
> = ({ onSuccess, onError }) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, payload }) =>
      updateBeneficiaries(accessToken, projectId, payload),
    onSuccess: (data, variables, context) => {
      client.invalidateQueries({
        queryKey: ['projects', variables.projectId],
        exact: true,
      });
      client.invalidateQueries({
        queryKey: ['projects', variables.projectId, 'step'],
      });

      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

export const mutations = {
  useUpdateBeneficiaries: useUpdateBeneficiaries,
};
