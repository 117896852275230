import { Icon } from '.';
import { IconProps } from './settings';

export const CompanyCircleIllustrationIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_157_697)" />
        <mask
          id="mask0_157_697"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path
            d="M20 1.9868e-06C31.0457 1.50397e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 2.46962e-06 20 1.9868e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_157_697)">
          <path
            d="M1.43182 17.4427L-10.0964 28.6753C-10.8029 29.3637 -11.1245 30.3561 -10.956 31.3281L-9.22899 41.2917C-8.97886 42.7348 -7.72044 43.7844 -6.25589 43.7714L50.2641 43.2727C52.3997 43.2539 53.8278 41.0671 52.9866 39.1041L38.7188 5.81264C37.7281 3.50115 34.5003 3.37014 33.3256 5.59373L22.2668 26.5264C21.3813 28.2027 19.1794 28.6425 17.7178 27.4351L5.42359 17.279C4.24633 16.3065 2.5255 16.3771 1.43182 17.4427Z"
            fill="url(#paint1_linear_157_697)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 6C9 4.34315 10.3431 3 12 3H29C30.6569 3 32 4.34315 32 6V37C32 38.6569 30.6569 40 29 40H23V33C23 31.8954 22.1046 31 21 31H20C18.8954 31 18 31.8954 18 33V40H12C10.3431 40 9 38.6569 9 37V6ZM11 15C11 14.4477 11.4477 14 12 14H15C15.5523 14 16 14.4477 16 15V19C16 19.5523 15.5523 20 15 20H12C11.4477 20 11 19.5523 11 19V15ZM12 6C11.4477 6 11 6.44772 11 7V11C11 11.5523 11.4477 12 12 12H15C15.5523 12 16 11.5523 16 11V7C16 6.44772 15.5523 6 15 6H12ZM11 23C11 22.4477 11.4477 22 12 22H15C15.5523 22 16 22.4477 16 23V27C16 27.5523 15.5523 28 15 28H12C11.4477 28 11 27.5523 11 27V23ZM19 14C18.4477 14 18 14.4477 18 15V19C18 19.5523 18.4477 20 19 20H22C22.5523 20 23 19.5523 23 19V15C23 14.4477 22.5523 14 22 14H19ZM18 7C18 6.44772 18.4477 6 19 6H22C22.5523 6 23 6.44772 23 7V11C23 11.5523 22.5523 12 22 12H19C18.4477 12 18 11.5523 18 11V7ZM26 14C25.4477 14 25 14.4477 25 15V19C25 19.5523 25.4477 20 26 20H29C29.5523 20 30 19.5523 30 19V15C30 14.4477 29.5523 14 29 14H26ZM25 7C25 6.44772 25.4477 6 26 6H29C29.5523 6 30 6.44772 30 7V11C30 11.5523 29.5523 12 29 12H26C25.4477 12 25 11.5523 25 11V7ZM19 22C18.4477 22 18 22.4477 18 23V27C18 27.5523 18.4477 28 19 28H22C22.5523 28 23 27.5523 23 27V23C23 22.4477 22.5523 22 22 22H19ZM25 23C25 22.4477 25.4477 22 26 22H29C29.5523 22 30 22.4477 30 23V27C30 27.5523 29.5523 28 29 28H26C25.4477 28 25 27.5523 25 27V23Z"
          fill="currentColor"
        />
        <defs>
          <filter
            id="filter0_i_157_697"
            x="9"
            y="3"
            width="25"
            height="39"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.976471 0 0 0 0 0.631373 0 0 0 0 0.658824 0 0 0 0.65 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_157_697"
            />
          </filter>
          <linearGradient
            id="paint0_linear_157_697"
            x1="20"
            y1="0"
            x2="20"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFAD69" />
            <stop offset="1" stop-color="#F05A95" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_157_697"
            x1="22.9804"
            y1="4.91537"
            x2="22.9804"
            y2="43.7939"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stopOpacity="0.6" />
            <stop offset="1" stop-color="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  );
};
