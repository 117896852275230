import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

import {
  DatePicker as DatePickerMui,
  DatePickerProps as DatePickerMuiProps,
} from '@mui/x-date-pickers/DatePicker';

import DatePickerActionBar from './DatePickerActionBar';
import DatePickerInputAdornment from './DatePickerInputAdornment';
import TextField from '../TextField/TextField';

export type DatePickerProps = Partial<DatePickerMuiProps<any, any>> & {
  variant?: 'filled' | 'outlined' | 'standard';
};

const DatePicker = (props: DatePickerProps) => {
  const theme = useTheme();

  return (
    <DatePickerMui
      {...props}
      value={props.value ? dayjs(props.value) : undefined}
      format="DD/MM/YYYY"
      localeText={{
        toolbarTitle: 'Sélectionnez une date',
        okButtonLabel: 'Valider',
      }}
      slots={{
        ...props.slots,
        textField: TextField,
        actionBar: DatePickerActionBar,
        inputAdornment: DatePickerInputAdornment,
      }}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          variant: props.variant,
        },
        openPickerButton: {
          sx: {
            color: theme.palette.text.secondary,
          },
        },
      }}
    />
  );
};

DatePicker.muiName = 'DatePicker';

export default DatePicker;
