import React from 'react';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PersonIcon } from 'components/Icon/PersonIcon';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { civilityLabels, Person } from 'models/oav/Person.models';
import FormCard from 'content/form/FormCard';
import { formatNni, getRegime } from 'utils/fields/fields';

interface IdentityViewTabProps {
  person: Person;
}

export const IdentityViewCard = (props: IdentityViewTabProps) => {
  const regime = getRegime(props.person.regimeCode);

  const regimeComponent = regime && (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Typography>{regime.label}</Typography>
    </Stack>
  );

  return (
    <FormCard icon={PersonIcon} label="Identité">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FieldReadOnly
            label="Civilité"
            value={
              props.person.civility &&
              civilityLabels[props.person.civility].label
            }
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FieldReadOnly label="Nom" value={props.person.lastname} />
        </Grid>
        <Grid item xs={6}>
          <FieldReadOnly label="Prénom" value={props.person.firstname} />
        </Grid>
        <Grid item xs={6}>
          <FieldReadOnly
            label="Date de naissance"
            value={dayjs(props.person.birthdate).format('DD/MM/YYYY')}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldReadOnly label="Régime social" value={regimeComponent} />
        </Grid>
        <Grid item xs={6}>
          <FieldReadOnly
            label="N° Sécurité sociale"
            value={formatNni(props.person.nni)}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldReadOnly label="Code caisse" value={props.person.cpamId} />
        </Grid>
        <Grid item xs={6}>
          <FieldReadOnly
            label="Rang de naissance"
            value={props.person.birthOrder?.toString()}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
