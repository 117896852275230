import React, { cloneElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

export type ButtonLinkProps = Partial<LinkProps> & {
  label?: string;
  icon: React.JSX.Element;
  iconPosition?: 'start' | 'end';
  color?: string;
  size?: 'small' | 'medium' | 'large';
  minified?: boolean;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: any;
};

const getLinkProps = (props: ButtonLinkProps) => {
  const {
    label,
    icon,
    iconPosition,
    color,
    size,
    minified,
    selected,
    onClick,
    children,
    ...linkProps
  } = props;
  return linkProps;
};

const ButtonLink = (props: ButtonLinkProps) => {
  const theme = useTheme();

  const linkProps = getLinkProps(props);
  const colorSelected = theme.palette.primary.main;
  const colorDefault = theme.palette.text.secondary;

  const color = props.color || colorDefault;
  const bgColor = props.color || colorSelected;

  const icon: React.JSX.Element = cloneElement(props.icon, {
    color: props.selected ? bgColor : color,
    size: props.size ? props.size : props.minified ? 'large' : 'medium',
  });

  const button: JSX.Element = (
    <Tooltip title={!props.minified ? '' : props.label} placement="right">
      <Button
        fullWidth={!props.minified}
        variant="text"
        onClick={props.onClick}
        sx={{
          justifyContent: props.minified
            ? 'center'
            : props?.iconPosition === 'end'
              ? 'space-between'
              : 'flex-start',
          minWidth: 'auto',
          px: props.minified ? 1 : 2,
          borderRadius: 3,
          textAlign: 'left',
          fontWeight: props.selected ? 700 : 500,
          color: props.selected ? bgColor : color,
          background: props.selected ? bgColor + '30' : 'none',
          ':hover': {
            background: props.selected ? bgColor + '30' : bgColor + '18',
          },
          '.MuiTouchRipple-child': {
            backgroundColor: bgColor + ' !important',
          },
        }}
        endIcon={props.iconPosition === 'end' && !props.minified ? icon : null}
        startIcon={
          (!props.iconPosition || props.iconPosition === 'start') &&
          !props.minified
            ? icon
            : null
        }
      >
        {props.minified ? icon : props.children ? props.children : props.label}
      </Button>
    </Tooltip>
  );

  return (
    <>
      {props.to ? (
        <Link {...linkProps} to={props.to} style={{ borderRadius: 3 }}>
          {button}
        </Link>
      ) : (
        button
      )}
    </>
  );
};

export default ButtonLink;
