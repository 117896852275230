import { RestError } from 'errors/RestError';
import {
  Beneficiary,
  UpdateBeneficiariesRequest,
} from 'models/oav/Beneficiary.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const updateBeneficiaries = async (
  accessToken: string,
  projectId: string,
  payload: UpdateBeneficiariesRequest,
): Promise<Beneficiary[]> => {
  const url = `${baseUrl}/${projectId}/beneficiaries`;

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PATCH',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};
