import { PersonSearchCriteriaRequest } from 'models/oav/Person.models';
import Box from '@mui/material/Box';
import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { nameMaxChar, nameMinChar, yupEmail } from 'utils/validation/yupPerson';
import TextField from 'components/TextField/TextField';
import Stack from '@mui/material/Stack';
import { FormikObserver } from 'utils/formik/FormikObserver';
import DatePicker from 'components/DatePicker/DatePicker';

interface PersonSearchFormProps {
  initialCriteria: PersonSearchCriteriaRequest;
  onChange: (criteria: PersonSearchCriteriaRequest, errors: object) => void;
}

export const validationSchema = Yup.object().shape({
  birthdate: Yup.date().typeError('Date non valide').nullable(),
  email: yupEmail,
});

export const PersonSearchForm: React.FC<PersonSearchFormProps> = (
  props: PersonSearchFormProps,
) => {
  return (
    <Box sx={{ p: 2, mt: 3 }}>
      <Formik
        initialValues={props.initialCriteria}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <FormikObserver onChange={props.onChange} />
            <Stack direction="row" justifyContent="space-between" gap={2}>
              <TextField
                fullWidth
                id="firstname"
                name="firstname"
                label="Prénom"
                value={values.firstname}
                error={touched.firstname && !!errors.firstname}
                helperText={touched.firstname && errors.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  minLength: nameMinChar,
                  maxLength: nameMaxChar,
                }}
              />
              <TextField
                fullWidth
                id="lastname"
                name="lastname"
                label="Nom"
                value={values.lastname}
                error={touched.lastname && !!errors.lastname}
                helperText={touched.lastname && errors.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  minLength: nameMinChar,
                  maxLength: nameMaxChar,
                }}
              />
              <DatePicker
                sx={{
                  width: '100%',
                }}
                label="Date de naissance"
                format="DD/MM/YYYY"
                openTo="year"
                name="birthdate"
                value={values.birthdate}
                onChange={v => setFieldValue('birthdate', v)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true,
                    onBlur: handleBlur,
                    error: !!touched.birthdate && !!errors.birthdate,
                    helperText: !!touched.birthdate && <>{errors.birthdate}</>,
                  },
                }}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-mail"
                value={values.email}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ minLength: 1, maxLength: 100 }}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
