import { Icon } from '.';
import { IconProps } from './settings';

export const ChevronBottomIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.75 5.625L9.3375 12.375L2.25 5.625"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
