import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { loadUserInfo } from 'api/Keycloak.api';
import { Agency, UserInfo } from 'model/UserInfo.model';
import { getAgency } from 'api/Authentication.api';

export type UserContextData = {
  user?: UserInfo;
  agency?: Agency;
};

export type UserContextProps = {
  data: UserContextData;
  setData: (_: UserContextData) => void;
};

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();

  const [data, setData] = useState<UserContextData>({});

  useEffect(() => {
    if (auth.user) {
      loadUserInfo(auth.user.access_token)
        .then(response => {
          setData({ user: response });
          return response;
        })
        .then(response => {
          if (response.agencyId) {
            getAgency(response.agencyId, auth.user?.access_token)
              .then(responseAgency => {
                setData({ user: response, agency: responseAgency });
              })
              .catch(error => console.error(error));
          }
        });
    }
  }, [auth]);

  return (
    <UserContext.Provider value={{ data, setData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider');
  }
  return context;
};
