import { RestError } from 'errors/RestError';
import {
  BankDetailsRequest,
  Subscription,
  SubscriptionRequest,
} from 'models/oav/Subscription.models';
import { Tarification } from 'models/referentiels/Tarification.models';
import { formatDate } from 'utils/api/api';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const updateSubscription = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: SubscriptionRequest,
): Promise<Subscription> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}`;
  const body = {
    ...payload,
    dateStart: formatDate(payload.dateStart),
  };
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      body,
      await response.json(),
    );

  return await response.json();
};

// Get tarification
export const fetchSubscriptionTarification = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<Tarification> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/tarification`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

// Update bank details
export const updateBankDetails = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: BankDetailsRequest,
): Promise<Subscription> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/bankDetails`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      payload,
      await response.json(),
    );

  return await response.json();
};
