import Typography from '@mui/material/Typography';

import AppBar from 'components/AppBar';
import { useUserContext } from 'components/Context/UserContext';
import { appName } from 'App';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import React from 'react';
import Stack from '@mui/material/Stack';
import { ButtonNewProject } from 'components/Button/ButtonNewProject/ButtonNewProject';
import { TableLastProject } from 'components/Table/TableLastProject';
import Box from '@mui/material/Box';

const DashboardPage: React.FC = () => {
  document.title = `Tableau de bord - ${appName}`;

  const userContext = useUserContext();

  return (
    <>
      <AppBar>
        <Stack
          sx={{
            width: '100%',
          }}
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
        >
          <Typography variant="h2">
            Bienvenue {userContext.data.user?.given_name} !
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
              width: '420px',
            }}
          >
            <ButtonNewProject fullWidth />
            <Link style={{ width: '100%' }} to="/personnes/nouveau">
              <Button size="medium" variant="gradient" fullWidth>
                Nouvelle personne
              </Button>
            </Link>
          </Stack>
        </Stack>
      </AppBar>

      <Stack direction="row" sx={{ width: '100%' }}>
        <Box sx={{ width: '75%', marginTop: 20 }}>
          <TableLastProject />
        </Box>
      </Stack>
    </>
  );
};

export default DashboardPage;
