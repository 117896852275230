import { SVGProps } from 'react';

export type IconProps = SVGProps<SVGSVGElement> & {
  color?: string;
  secondaryColor?: string;
  bgColor?: string;
  width?: string;
  height?: string;
  size?: 'small' | 'medium' | 'large';
};

export type IconSizeProps = {
  width: string;
  height: string;
};

export const getSize = (size: 'small' | 'medium' | 'large'): IconSizeProps => {
  switch (size) {
    case 'small':
      return IconSizeSmall;

    case 'large':
      return IconSizeLarge;

    case 'medium':
    default:
      return IconSizeMedium;
  }
};

const IconSizeSmall: IconSizeProps = {
  width: '20',
  height: '20',
};

const IconSizeMedium: IconSizeProps = {
  width: '25',
  height: '25',
};

const IconSizeLarge: IconSizeProps = {
  width: '30',
  height: '30',
};

export const defaultIconSize: IconSizeProps = IconSizeMedium;
