import { useTheme } from '@mui/material/styles';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {
  Select as SelectMui,
  SelectProps as SelectMuiProps,
} from '@mui/material';

import { ChevronBottomIcon } from '../../Icon/ChevronBottomIcon';

export type SelectProps<Value = unknown> = Partial<SelectMuiProps<Value>> & {
  fullWidth?: boolean;
  label?: string;
  error?: boolean;
  children: any;
};

const Select = <Value = unknown,>(props: SelectProps<Value>) => {
  const theme = useTheme();

  return (
    <FormControl
      fullWidth={props.fullWidth}
      variant={props.variant || 'filled'}
    >
      {props.label && (
        <InputLabel error={props.error} required={props.required}>
          {props.label}
        </InputLabel>
      )}
      <SelectMui<Value>
        {...props}
        disableUnderline
        IconComponent={params => (
          <ChevronBottomIcon
            {...params}
            size="small"
            style={{
              mr: -2,
              color: theme.palette.text.secondary,
            }}
          />
        )}
      >
        {props.children}
      </SelectMui>
    </FormControl>
  );
};

export default Select;
