import * as Yup from 'yup';

export const ageChildMin = 0;
export const ageChildMax = 28;
export const ageAdultMin = 18;
export const ageAdultMax = 200;

export const nameMaxChar = 100;
export const nameMinChar = 2;

export const birthOrderMin = 1;
export const birthOrderMax = 50;
export const cpamIdSize = 3;
export const postalCodeSize = 5;
export const nniSize = 15;

export const getDateFromAge = (age: number, from?: Date): Date => {
  const date = from ? new Date(from) : new Date();
  date.setFullYear(date.getFullYear() - age);
  return date;
};

export const getYupFieldBirthDateAdult = (from?: Date) => {
  return Yup.date()
    .typeError('Date non valide')
    .nullable()
    .test(
      'min',
      `Date non valide (un adulte doit avoir ${ageAdultMin} ans ou plus)`,
      value => value!.getTime() < getDateFromAge(ageAdultMin, from).getTime(),
    )
    .test(
      'max',
      `Date non valide (un adulte ne peut pas avoir plus de ${ageAdultMax} ans)`,
      value => value!.getTime() > getDateFromAge(ageAdultMax, from).getTime(),
    );
};

export const getYupFieldBirthDateChild = (from?: Date) => {
  return Yup.date()
    .typeError('Date non valide')
    .nullable()
    .test(
      'min',
      `Date non valide (un enfant doit avoir ${ageChildMin} ans ou plus)`,
      value => value!.getTime() < getDateFromAge(ageChildMin, from).getTime(),
    )
    .test(
      'max',
      `Date non valide (un enfant ne peut pas avoir plus de ${ageChildMax} ans)`,
      value => value!.getTime() > getDateFromAge(ageChildMax, from).getTime(),
    );
};

export const getYupBirthDate = (from?: Date) => {
  return Yup.date()
    .typeError('Date non valide')
    .nullable()
    .test(
      'min',
      `Date non valide (une personne doit avoir ${ageChildMin} ans ou plus)`,
      value => value!.getTime() < getDateFromAge(ageChildMin, from).getTime(),
    )
    .test(
      'max',
      `Date non valide (une personne ne peut pas avoir plus de ${ageAdultMax} ans)`,
      value => value!.getTime() > getDateFromAge(ageAdultMax, from).getTime(),
    );
};

export const yupFieldPostalCode = Yup.string()
  .matches(/^[0-9]+$/, 'Code postal non valide (uniquement des chiffres)')
  .min(
    postalCodeSize,
    `Code postal non valide (${postalCodeSize} caractères requis)`,
  )
  .max(
    postalCodeSize,
    `Code postal non valide (${postalCodeSize} caractères requis)`,
  );

export const yupFieldFirstName = Yup.string()
  .trim()
  .min(
    nameMinChar,
    `Prénom non valide (minimum ${nameMinChar} caractères requis)`,
  )
  .max(nameMaxChar, `Prénom non valide (maximum ${nameMaxChar} caractères)`);

export const yupFieldLastName = Yup.string()
  .trim()
  .min(nameMinChar, `Nom non valide (minimum ${nameMinChar} caractères requis)`)
  .max(nameMaxChar, `Nom non valide (maximum ${nameMaxChar} caractères)`);

export const yupCpamId = Yup.string()
  .matches(/^[0-9]+$/, 'Code caisse non valide (uniquement des chiffres)')
  .min(cpamIdSize, `Code caisse non valide (${cpamIdSize} caractères requis)`)
  .max(cpamIdSize, `Code caisse non valide (${cpamIdSize} caractères requis)`);

export const yupNni = Yup.string()
  .matches(
    /^[0-9]+$/,
    'N° Sécurité sociale non valide (uniquement des chiffres)',
  )
  .min(nniSize, `N° Sécurité sociale non valide (${nniSize} caractères requis)`)
  .max(
    nniSize,
    `N° Sécurité sociale non valide (${nniSize} caractères requis)`,
  );

export const yupBirthOrder = Yup.number()
  .min(
    birthOrderMin,
    `Rang de naissance non valide (ne peut pas être inférieur à ${birthOrderMin})`,
  )
  .max(
    birthOrderMax,
    `Rang de naissance non valide (ne peut pas être supérieur à ${birthOrderMax})`,
  );

export const yupEmail = Yup.string().email('Email non valide');

export const yupFieldPhone = Yup.object({
  number: Yup.string(),
  countryCode: Yup.string(),
});

export type Phone = {
  number?: string;
  countryCode?: string;
};

export const formatPhoneRequest = (phone: Phone) => {
  if (!phone.number) return undefined;
  if (phone.countryCode && phone.number === phone.countryCode) return undefined;

  let formatted = phone.number;
  formatted = formatted.replace(/[\s\-\(\)\+]/g, '');
  formatted = `+${formatted}`;

  return formatted;
};
