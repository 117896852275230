import { Icon } from '.';
import { IconProps } from './settings';

export const AccountIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.26669 7.91155C7.26669 4.80409 9.78578 2.285 12.8932 2.285C16.0007 2.285 18.5198 4.80409 18.5198 7.91155V10.965H7.26669V7.91155Z"
          stroke="currentColor"
          strokeWidth="4.07"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.47 9.76416C2.72514 9.76416 0.5 11.9893 0.5 14.7342V23.0298C0.5 25.7746 2.72514 27.9998 5.47 27.9998H20.2981C23.043 27.9998 25.2681 25.7746 25.2681 23.0298V14.7342C25.2681 11.9893 23.043 9.76416 20.2981 9.76416H5.47ZM15.5222 16.3162C15.5222 17.061 15.1397 17.7255 14.5415 18.1602L15.0336 21.8753C15.2049 23.1692 14.1981 24.3181 12.893 24.3181C11.5798 24.3181 10.5707 23.1557 10.7551 21.8556L11.274 18.1974C10.6458 17.764 10.2408 17.0825 10.2408 16.3162C10.2408 15.0067 11.4231 13.9452 12.8815 13.9452C14.3399 13.9452 15.5222 15.0067 15.5222 16.3162Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
