import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Skeleton from '@mui/material/Skeleton';
import { appName } from 'App';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import {
  supportingDocumentIcons,
  supportingDocumentSort,
  SupportingDocumentTypeCode,
} from 'models/referentiels/Document.models';
import { IconProps } from 'components/Icon/settings';
import Tooltip from '@mui/material/Tooltip';
import { FormControlLabel } from '@mui/material';
import { InfoIcon } from 'components/Icon/InfoIcon';
import Box from '@mui/material/Box';
import TextField from 'components/TextField/TextField';
import Checkbox from '@mui/material/Checkbox';
import { Form, Formik, FormikProps, getIn } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import { supportingDocumentsQueries } from 'api/oav/SupportingDocumentQuery.api';
import DialogId from 'content/project/supportingDocument/DialogId';
import { CheckCircleFilledIcon } from 'components/Icon/CheckCircleFilledIcon';
import {
  projectStepMutations,
  projectStepQueries,
} from 'api/oav/ProjectStepQuery.api';
import DialogChildMajority from 'content/project/supportingDocument/DialogChildMajority';
import DialogKbis from 'content/project/supportingDocument/DialogKbis';
import DialogBankDetails from 'content/project/supportingDocument/DialogBankDetails';
import {
  ProjectStepContractValidation,
  ProjectStepContractValidationBeneficiaryErrors,
} from 'models/oav/Project.models';
import { NeededSupportingDocument } from 'models/oav/SupportingDocument.models';
import DialogCPAM from 'content/project/supportingDocument/DialogCPAM';

const CURRENT_STEP = ProjectStep.CONTRACT;

interface FormikValues {
  email: string;
  confirm: boolean;
  supportingDocuments: NeededSupportingDocument[];
  stepValidation: ProjectStepContractValidation;
}

const errorsPresentForBankDetails = (
  errors: ProjectStepContractValidation['errors'],
) => {
  if (!errors) {
    return false;
  }

  return Object.entries(errors)
    .filter(([_]) => _.startsWith('subscriptions['))
    .find(([, _]) => {
      const subErrors =
        _ as ProjectStepContractValidation['errors']['subscriptions[0]'];
      return (
        subErrors?.debitBankDetails !== undefined ||
        subErrors?.creditBankDetails !== undefined ||
        subErrors?.paymentFrequency !== undefined ||
        subErrors?.paymentDay !== undefined
      );
    });
};

const errorsCpamBeneficiary = (
  errors: ProjectStepContractValidationBeneficiaryErrors,
) => {
  return (
    errors?.remoteTransmission !== undefined ||
    errors?.person?.nni !== undefined ||
    errors?.person?.cpamId !== undefined ||
    errors?.person?.birthOrder !== undefined
  );
};

const errorsPresentForCPAM = (
  errors: ProjectStepContractValidation['errors'],
) => {
  if (!errors) {
    return false;
  }

  const childrenErrors = Object.entries(errors)
    .filter(([_]) => _.startsWith('children['))
    .find(([, _]) =>
      errorsCpamBeneficiary(
        _ as ProjectStepContractValidationBeneficiaryErrors,
      ),
    );

  const partnerErrors = errors.partner
    ? errorsCpamBeneficiary(errors.partner)
    : undefined;
  const subErrors = errors.subscriber
    ? errorsCpamBeneficiary(errors.subscriber)
    : undefined;

  return !!childrenErrors || !!partnerErrors || !!subErrors;
};

const validationSchema = Yup.object().shape({
  confirm: Yup.boolean().isTrue('Confirmation obligatoire'),
  email: Yup.string().email('Email non valide'),
  supportingDocuments: Yup.array().of(
    Yup.object().test(
      'documents',
      () => 'Veuillez compléter toutes les pièces justificatives',
      (_, context) => {
        const value = _ as NeededSupportingDocument;

        // Check number of awaited supporting documents
        const nbDocuments = value.documents?.length ?? 0;
        if (nbDocuments < value.min || nbDocuments > value.max) {
          return false;
        }

        // Check step form
        const stepValidation = context.options.context
          ?.stepValidation as ProjectStepContractValidation;
        if (stepValidation.valid) {
          return true;
        }
        if (value.type === SupportingDocumentTypeCode.BANK_DETAILS) {
          return !errorsPresentForBankDetails(stepValidation.errors);
        } else if (value.type === SupportingDocumentTypeCode.CPAM) {
          return !errorsPresentForCPAM(stepValidation.errors);
        }
        return true;
      },
    ),
  ),
});

const sortNeededDocuments = (_: NeededSupportingDocument[]) =>
  _.sort(
    (a, b) =>
      supportingDocumentSort[a.type].sort - supportingDocumentSort[b.type].sort,
  );

const ProjectContractPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const theme = useTheme();

  const projectQuery = useQuery(projectQueries.getById(id));
  const supportingDocumentsQuery = useQuery({
    ...supportingDocumentsQueries.get(id),
    select: sortNeededDocuments,
  });
  const stepValidationQuery = useQuery(
    projectStepQueries.getContractValidation(id),
  );
  const { addSnackbar } = useAppContext();

  const { mutate, isPending } =
    projectStepMutations.updateProjectStepFormulaChoice(id, {
      onSuccess: () => {
        navigateToNextStep(CURRENT_STEP, id, navigate);
      },
      onError: () => {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      },
    });

  const [openDialog, setOpenDialog] =
    React.useState<SupportingDocumentTypeCode | null>(null);

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  useEffect(() => {
    if (formikRef.current && stepValidationQuery.data) {
      formikRef.current.setFieldValue(
        'stepValidation',
        stepValidationQuery.data,
      );
    }
  }, [stepValidationQuery.data]);

  useEffect(() => {
    if (formikRef.current && supportingDocumentsQuery.data) {
      formikRef.current.setFieldValue(
        'supportingDocuments',
        supportingDocumentsQuery.data,
      );
    }
  }, [supportingDocumentsQuery.data]);

  const error =
    projectQuery.error ||
    supportingDocumentsQuery.error ||
    stepValidationQuery.error;
  if (error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={error instanceof RestError ? error.status : undefined}
        />
      </Grid>
    );
  }

  if (
    !projectQuery.data ||
    !supportingDocumentsQuery.data ||
    !stepValidationQuery.data
  ) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const handleSubmit = (values: FormikValues) => {
    // TODO: handle submit when upload document will be developed
  };

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const initialValues = {
    email: projectQuery.data?.subscriber?.person?.email || '',
    confirm: false,
    supportingDocuments: supportingDocumentsQuery.data,
    stepValidation: stepValidationQuery.data,
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h3">Contractualisation</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      borderColor: theme.palette.divider,
                    }}
                  >
                    <Typography variant="body1">
                      Les pièces justificatives de votre client sont nécessaires
                      à la validation de sa souscription.
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      borderColor: theme.palette.divider,
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Transmettez les pièces justificatives et complétez les
                      informations :
                    </Typography>
                    <Stack direction="column" spacing={2} alignItems="center">
                      {values.supportingDocuments.map((_, index) => {
                        const error = getIn(
                          errors,
                          `supportingDocuments[${index}]`,
                        );
                        const isTouched = getIn(
                          touched,
                          `supportingDocuments[${index}]`,
                        );
                        const checked = error === undefined;
                        return (
                          <>
                            <Card
                              key={_.type}
                              sx={{
                                width: '100%',
                                pr: 2,
                                pl: 4,
                                py: 0,
                                backgroundColor:
                                  theme.palette.background.default,
                                position: 'relative',
                                overflow: 'visible',
                              }}
                            >
                              {_.type === SupportingDocumentTypeCode.ID ? (
                                <DialogId
                                  open={openDialog === _.type}
                                  onClose={closeDialog}
                                  neededDocuments={_}
                                  projectId={id}
                                />
                              ) : _.type ===
                                SupportingDocumentTypeCode.CHILD_MAJORITY ? (
                                <DialogChildMajority
                                  open={openDialog === _.type}
                                  onClose={closeDialog}
                                  neededDocuments={_}
                                  projectId={id}
                                />
                              ) : _.type === SupportingDocumentTypeCode.KBIS ? (
                                <DialogKbis
                                  open={openDialog === _.type}
                                  onClose={closeDialog}
                                  neededDocuments={_}
                                  projectId={id}
                                />
                              ) : _.type ===
                                SupportingDocumentTypeCode.BANK_DETAILS ? (
                                <DialogBankDetails
                                  project={projectQuery.data}
                                  open={openDialog === _.type}
                                  onClose={closeDialog}
                                  neededDocuments={_}
                                  projectId={id}
                                />
                              ) : _.type === SupportingDocumentTypeCode.CPAM ? (
                                <DialogCPAM
                                  open={openDialog === _.type}
                                  onClose={closeDialog}
                                  neededDocuments={_}
                                  projectId={id}
                                  project={projectQuery.data}
                                />
                              ) : (
                                <></>
                              )}

                              {checked && (
                                <CheckCircleFilledIcon
                                  size="small"
                                  style={{
                                    color: theme.palette.success.main,
                                    position: 'absolute',
                                    left: 0,
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                />
                              )}
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                              >
                                {React.createElement<IconProps>(
                                  supportingDocumentIcons[_.type].icon,
                                  {
                                    color: theme.palette.text.disabled,
                                    width: '70',
                                    height: '70',
                                  },
                                )}
                                <Typography
                                  sx={{ marginLeft: 3 }}
                                  variant="body1"
                                >
                                  {_.label}
                                </Typography>
                                <Tooltip
                                  title={_.description}
                                  placement="top"
                                  arrow
                                >
                                  <Box
                                    sx={{
                                      my: 'auto',
                                      ml: 2,
                                      width: '20px',
                                      height: '20px',
                                    }}
                                  >
                                    <InfoIcon
                                      color={theme.palette.text.disabled}
                                      size="small"
                                    />
                                  </Box>
                                </Tooltip>
                                {checked ? (
                                  <Button
                                    variant="contained"
                                    color="default"
                                    sx={{ width: '150px', px: 4, ml: 'auto' }}
                                    onClick={() => setOpenDialog(_.type)}
                                  >
                                    Modifier
                                  </Button>
                                ) : (
                                  <Button
                                    variant="gradient"
                                    sx={{
                                      width: '150px',
                                      color: theme.palette.background.default,
                                      background:
                                        'linear-gradient(270deg, #52E09C 0%, #59CCD3 100%)',
                                      px: 4,
                                      ml: 'auto',
                                    }}
                                    onClick={() => setOpenDialog(_.type)}
                                  >
                                    Compléter
                                  </Button>
                                )}
                              </Stack>
                            </Card>
                            {error && isTouched && (
                              <FormHelperText
                                error
                                sx={{ alignSelf: 'flex-start' }}
                              >
                                {error}
                              </FormHelperText>
                            )}
                          </>
                        );
                      })}
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      borderColor: theme.palette.divider,
                    }}
                  >
                    <Stack direction="column" spacing={2}>
                      <Typography variant="body1">
                        <strong>Envoyez par mail</strong> le lien de signature
                        électronique à votre client ou{' '}
                        <strong>importez son projet</strong> signé manuellement.
                      </Typography>
                      <TextField
                        variant="filled"
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        id="email"
                        name="email"
                        label="Email de votre client"
                        value={values.email}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ minLength: 1, maxLength: 100 }}
                      />
                      <span>
                        <FormControlLabel
                          control={<Checkbox />}
                          checked={values.confirm}
                          name="confirm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            color:
                              touched.confirm && !!errors.confirm
                                ? theme.palette.error.main
                                : undefined,
                          }}
                          label="Je confirme que l’ensemble des pièces justificatives demandées a été vérifié avant souscription.*"
                        />
                        {touched.confirm && !!errors.confirm && (
                          <FormHelperText error>
                            {errors.confirm}
                          </FormHelperText>
                        )}
                      </span>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm="auto" xs={12}>
              <Button
                fullWidth
                color="default"
                disabled={isPending}
                onClick={() =>
                  navigateToPreviousStep(CURRENT_STEP, id, navigate)
                }
                sx={{ px: 4 }}
              >
                Retour
              </Button>
            </Grid>

            <Grid item sm="auto" xs={12}>
              <Button
                fullWidth
                type="submit"
                disabled={isPending}
                sx={{ px: 4 }}
              >
                {isPending ? (
                  <CircularProgress color="inherit" size={28} />
                ) : (
                  'Valider'
                )}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectContractPage;
