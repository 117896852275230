export enum ProjectStatus {
  IN_PROGRESS,
  WAITING_FOR_PARTS,
  PARTS_TO_VALIDATE,
  WAITING_FOR_SIGNATURE,
  VALIDATED,
  EXPIRED,
  CANCELED,
  MANAGEMENT_FAILURE,
}

export type ProjectStatusData = {
  label: string;
  color: string;
};

export const projectStatusData: Record<ProjectStatus, ProjectStatusData> = {
  [ProjectStatus.IN_PROGRESS]: {
    label: 'En cours de saisie',
    color: '#B880DB',
  },
  [ProjectStatus.WAITING_FOR_PARTS]: {
    label: 'Attente de pièces',
    color: '#F89136',
  },
  [ProjectStatus.PARTS_TO_VALIDATE]: {
    label: 'Pièces à valider',
    color: '#F89136',
  },
  [ProjectStatus.WAITING_FOR_SIGNATURE]: {
    label: 'Attente de signature',
    color: '#F89136',
  },
  [ProjectStatus.VALIDATED]: {
    label: 'Validé',
    color: '#52E09C',
  },
  [ProjectStatus.EXPIRED]: {
    label: 'Expiré',
    color: '#AFB6C8',
  },
  [ProjectStatus.CANCELED]: {
    label: 'Annulé',
    color: '#AFB6C8',
  },
  [ProjectStatus.MANAGEMENT_FAILURE]: {
    label: 'Échec de mise en gestion',
    color: 'red',
  },
};
