import React from 'react';
import { useUserContext } from 'components/Context/UserContext';
import { Grid } from '@mui/material';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';

const AccountUserPage: React.FC = () => {
  const user = useUserContext();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldReadOnly
            label={'Identifiant'}
            value={user.data.user?.preferred_username}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldReadOnly label={'Email'} value={user.data.user?.email} />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountUserPage;
