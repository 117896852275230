import React, { Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';

import { SupportingDocument } from 'models/oav/SupportingDocument.models';
import { DropzoneDocument } from 'components/Dropzone/DropzoneDocument';
import { FileContext } from 'utils/project/supportingDocuments';
import CardSupportingDocuments from 'content/project/supportingDocument/CardSupportingDocuments';
import { SupportingDocumentTypeCode } from 'models/referentiels/Document.models';

interface DropzoneBankDetailsProps {
  setPreviewDocument: Dispatch<SetStateAction<SupportingDocument | undefined>>;
  previewDocument?: SupportingDocument;
  onDelete: (file: FileContext) => () => void;
  onDrop: (acceptedFiles: File[]) => void;
  projectId: string;
  files: FileContext[];
}

const DropzoneBankDetails: React.FC<DropzoneBankDetailsProps> = ({
  setPreviewDocument,
  previewDocument,
  onDelete,
  projectId,
  files,
  onDrop,
}) => {
  return (
    <>
      {files.length > 0 ? (
        <>
          {files.map((_, index) => (
            <Grid key={index} item xs={12}>
              <CardSupportingDocuments
                projectId={projectId}
                file={_}
                type={SupportingDocumentTypeCode.BANK_DETAILS}
                onDelete={onDelete(_)}
                onPreview={setPreviewDocument}
                viewing={_.id ? previewDocument?.id === _.id : false}
              />
            </Grid>
          ))}
        </>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            minHeight: '260px',
          }}
        >
          <DropzoneDocument
            maxFiles={1}
            onDrop={onDrop}
            nbFiles={files.length}
            label={
              <>
                Glissez et déposez <strong>votre RIB</strong> ici
              </>
            }
            helper="Taille maximale : 10Mo - Formats acceptés : .pdf, .jpeg, .jpg, .png, .bmp, .webp"
          />
        </Grid>
      )}
    </>
  );
};

export default DropzoneBankDetails;
