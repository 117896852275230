import { Icon } from '.';
import { IconProps } from './settings';

export const PersonTrioCircleIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="15" fill={props.bgColor} />
        <path
          d="M7.5 26.625C7.5 22.69 10.69 19.5 14.625 19.5C18.56 19.5 21.75 22.69 21.75 26.625V30H7.5V26.625Z"
          fill="currentColor"
        />
        <circle cx="14.625" cy="13.875" r="4.875" fill="currentColor" />
        <path
          d="M0 28.7266C0 25.8271 2.35051 23.4766 5.25 23.4766C8.1495 23.4766 10.5 25.8271 10.5 28.7266V30.1629H0V28.7266Z"
          fill="currentColor"
        />
        <circle cx="5.26797" cy="19.8432" r="3.34316" fill="currentColor" />
        <path
          d="M19.5 28.7266C19.5 25.8271 21.8505 23.4766 24.75 23.4766C27.6495 23.4766 30 25.8271 30 28.7266V30.1629H19.5V28.7266Z"
          fill="currentColor"
        />
        <circle cx="24.768" cy="19.8432" r="3.34316" fill="currentColor" />
      </svg>
    </Icon>
  );
};
