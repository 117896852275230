import { CSSProperties } from 'react';

import { TextField as TextFieldMui } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

const TextField = (props: TextFieldProps) => {
  const style: CSSProperties = {};

  if (props.variant === 'filled' && !props.label) style.paddingTop = 8;

  return (
    <TextFieldMui
      {...props}
      InputProps={{ ...props.InputProps, disableUnderline: true }}
      inputProps={{ ...props.inputProps, style: { ...style } }}
    />
  );
};

TextField.muiName = 'TextField';

export default TextField;
