import Typography from '@mui/material/Typography';
import React from 'react';

const AccountProtocolPage: React.FC = () => {
  return (
    <>
      <Typography>Infos du protocole</Typography>
    </>
  );
};

export default AccountProtocolPage;
