import { RestError } from 'errors/RestError';
import {
  Project,
  ProjectStepAddressDetails,
  ProjectStepClientsInfo,
  ProjectStepContact,
  ProjectStepContractValidation,
  ProjectStepFormulaChoice,
  ProjectStepNeedsRequest,
  ProjectStepProposal,
  ProjectStepSubscriptions,
} from 'models/oav/Project.models';
import { formatDate } from 'utils/api/api';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const updateProjectStepSubscriptions = async (
  accessToken: string,
  id: string,
  payload: ProjectStepSubscriptions,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/subscriptions`;
  const body = {
    subscriptions: payload.subscriptions.map(s => ({
      ...s,
      dateStart: formatDate(s.dateStart),
    })),
  };
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(body),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepContact = async (
  accessToken: string,
  id: string,
  payload: ProjectStepContact,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/contact`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepClientsInfo = async (
  accessToken: string,
  id: string,
  payload: ProjectStepClientsInfo,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/clients-info`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepNeeds = async (
  accessToken: string,
  id: string,
  payload: ProjectStepNeedsRequest,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/needs`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepProposal = async (
  accessToken: string,
  id: string,
  payload: ProjectStepProposal,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/proposal`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepAddressDetails = async (
  accessToken: string,
  id: string,
  payload: ProjectStepAddressDetails,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/address-details`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const updateProjectStepFormulaChoice = async (
  accessToken: string,
  id: string,
  payload: ProjectStepFormulaChoice,
): Promise<Project> => {
  const url = `${baseUrl}/${id}/step/formula-choice`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const fetchProjectStepContractValidation = async (
  accessToken: string,
  id: string,
): Promise<ProjectStepContractValidation> => {
  const url = `${baseUrl}/${id}/step/contract/validation`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};
