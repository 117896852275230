import { createElement, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { AlertProps } from '@mui/material';

export const getDefaultErrorSnackBar = (
  message: string,
): { variant: AlertProps['severity']; message: ReactNode } => ({
  variant: 'error',
  message: createElement(() => {
    return <Typography variant="caption">{message}</Typography>;
  }),
});

export const getDefaultSuccessSnackBar = (
  message: string,
): { variant: AlertProps['severity']; message: ReactNode } => ({
  variant: 'success',
  message: createElement(() => {
    return <Typography variant="caption">{message}</Typography>;
  }),
});
