export type BankDetails = {
  id: string;
  bic?: string;
  iban?: string;
  accountHolder?: string;
};

export enum BankDetailsType {
  'DEBIT' = 'DEBIT',
  'CREDIT' = 'CREDIT',
}
