import React from 'react';
import Card from '@mui/material/Card';
import ButtonClose from 'components/Button/ButtonClose';
import { SupportingDocument } from 'models/oav/SupportingDocument.models';

interface PreviewDocumentProps {
  document: SupportingDocument;
  onClose: () => void;
}

const PreviewDocument: React.FC<PreviewDocumentProps> = ({
  document,
  onClose,
}) => {
  return (
    <Card
      sx={{
        padding: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ButtonClose sx={{ ml: 'auto' }} onClick={onClose} />
      <div style={{ height: '100%', overflow: 'auto' }}>
        <embed
          key={document.id}
          src={document.downloadUrl}
          style={{
            display: 'block',
            width: '100%',
            height: document.mimeType.startsWith('image/') ? undefined : '100%',
          }}
        />
      </div>
    </Card>
  );
};

export default PreviewDocument;
