import { RestError } from 'errors/RestError';
import {
  NeededSupportingDocument,
  SupportingDocument,
  UploadSupportingDocumentRequest,
} from 'models/oav/SupportingDocument.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const fetchSupportingDocuments = async (
  accessToken: string,
  projectId: string,
): Promise<NeededSupportingDocument[]> => {
  const url = `${baseUrl}/${projectId}/supporting-document`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const fetchSupportingDocument = async (
  accessToken: string,
  projectId: string,
  supportingDocumentId: string,
): Promise<SupportingDocument> => {
  const url = `${baseUrl}/${projectId}/supporting-document/${supportingDocumentId}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const uploadSupportingDocument = async (
  accessToken: string,
  projectId: string,
  file: File,
  payload: UploadSupportingDocumentRequest,
): Promise<SupportingDocument> => {
  const url = `${baseUrl}/${projectId}/supporting-document`;

  const formData = new FormData();
  formData.append(
    'supporting-document',
    new Blob([JSON.stringify(payload)], { type: 'application/json' }),
  );
  formData.append('file', file);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const deleteSupportingDocument = async (
  accessToken: string,
  projectId: string,
  supportingDocumentId: string,
): Promise<void> => {
  const url = `${baseUrl}/${projectId}/supporting-document/${supportingDocumentId}`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      undefined,
      await response.json(),
    );

  return;
};
