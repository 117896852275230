import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import {
  ProjectStatus,
  projectStatusData,
} from 'models/oav/ProjectStatus.models';

export const ChipProjectStatus = (props: { projectStatus: ProjectStatus }) => {
  const theme = useTheme();

  return (
    <Chip
      label={projectStatusData[props.projectStatus].label}
      color="default"
      variant="outlined"
      size="medium"
      sx={{
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        borderColor: projectStatusData[props.projectStatus].color,
      }}
      icon={
        <span
          style={{
            color: projectStatusData[props.projectStatus].color,
            marginBottom: '2px',
          }}
        >
          ⬤
        </span>
      }
    />
  );
};
