import { Pagination } from 'models/Page.models';
import { addParams } from 'utils/api/params';
import { Contract, ContractSearchRequest } from 'models/oav/Contract.model';
import { PageResponse } from 'models/api.model';
import { WrongStatusError } from 'api/referentiels';

const url = `${process.env.REACT_APP_API_URI_BASE}/oav/contracts`;

export const fetchContractList = async (
  pagination: Pagination,
  criteria: ContractSearchRequest,
  sort: string,
  accessToken?: string,
): Promise<PageResponse<Contract>> => {
  const params = new URLSearchParams();
  params.append('page', pagination.page!.toString());
  params.append('pageSize', pagination.size!.toString());
  if (sort) {
    params.append('sort', sort);
  }

  const formattedCriteria = {
    ...criteria,
    segments: criteria.segments ? criteria.segments : undefined,
    risks: criteria.risks ? [criteria.risks] : undefined,
    statuses: criteria.statuses ? [criteria.statuses] : undefined,
    offers: criteria.offers ? [criteria.offers] : undefined,
    subscriberCodes: criteria.subscriberCodes
      ? [criteria.subscriberCodes]
      : undefined,
    search: criteria.search || '',
  };

  addParams(params, formattedCriteria);

  const response = await fetch(`${url}?${params}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });

  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }

  return await response.json();
};

export const fetchContractByCode = async (
  code: string,
  accessToken?: string,
): Promise<Contract> => {
  const response = await fetch(`${url}/${code}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });

  if (response.status !== 200) {
    throw new WrongStatusError(response.status, 'Wrong http status.');
  }

  return await response.json();
};
