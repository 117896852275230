import React from 'react';

import { WarrantyAcronym } from 'models/referentiels/Warranty.model';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type ChipWarrantyAcronymProps = {
  acronym: WarrantyAcronym;
};

export const ChipWarrantyAcronym: React.FC<ChipWarrantyAcronymProps> = (
  props: ChipWarrantyAcronymProps,
) => {
  return (
    <Tooltip
      title={
        <Stack
          sx={{
            maxWidth: '200px',
          }}
        >
          <Typography>{props.acronym.acronym}</Typography>
          <Typography variant="caption">{props.acronym.description}</Typography>
        </Stack>
      }
    >
      <Chip
        sx={{
          cursor: 'pointer',
          borderRadius: 1,
          minHeight: '24px',
          height: 'auto',
          px: 0.5,
          '.MuiChip-label': {
            p: 0,
          },
        }}
        variant="outlined"
        label={props.acronym.acronym}
      />
    </Tooltip>
  );
};
