import { useMemo } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import FormattedTextField from './FormattedTextField';
import { ibanRegex } from 'utils/fields/fields';

const toUppercase = (value: string) => value.toUpperCase();
const removeRegex = /[^A-Z\d]/g;

export const IBANTextField = (props: TextFieldProps) => {
  const inputProps = useMemo(() => {
    return {
      ...props.inputProps,
      minLength: 33,
      maxLength: 33,
    };
  }, [props.inputProps]);

  return (
    <FormattedTextField
      {...props}
      groupsRegex={ibanRegex}
      removeRegex={removeRegex}
      separator=" "
      transform={toUppercase}
      inputProps={inputProps}
    />
  );
};
