import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { frFR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/fr';
import { AlertProps } from '@mui/material';

const localStorageLabel = 'appContext';

export type AppContextData = {
  theme: string;
  sidebarMinified: boolean;
};

export type AppContextProps = {
  data: AppContextData;
  setData: React.Dispatch<React.SetStateAction<AppContextData>>;
  addSnackbar: (snack: {
    variant: AlertProps['severity'];
    message: ReactNode;
  }) => void;
};

const AppContext = createContext<AppContextProps | undefined>(undefined);

const getInitialContext = (): AppContextData => {
  const data = localStorage.getItem(localStorageLabel);
  return data
    ? JSON.parse(data)
    : {
        theme: 'blue',
        sidebarMinified: false,
      };
};

export interface SnackbarContext {
  variant: AlertProps['severity'];
  content: ReactNode;
  key: number;
}

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState(getInitialContext());

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    localStorage.setItem(localStorageLabel, JSON.stringify(data));
  }, [data]);

  const addSnackbar: AppContextProps['addSnackbar'] = snack =>
    enqueueSnackbar({ ...snack });

  return (
    <AppContext.Provider value={{ data, setData, addSnackbar: addSnackbar }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="fr"
        localeText={
          frFR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        {children}
      </LocalizationProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
