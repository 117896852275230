import { Icon } from '.';
import { IconProps } from './settings';

export const DocumentIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'80'}
        height={'80'}
        fill="none"
        viewBox="0 0 80 80"
      >
        <rect
          width="49.65"
          height="60"
          x="15.175"
          y="10"
          stroke={props.color}
          rx="3.5"
        ></rect>
        <path
          stroke={props.color}
          strokeLinecap="round"
          d="M22.675 24h18M22.675 32h33M22.675 40h33M22.675 48h33M22.675 56h33"
        ></path>
      </svg>
    </Icon>
  );
};
