import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import {
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

type TableCellLinkProps = {
  to: string;
  children?: React.ReactNode;
} & TableCellProps;

/*
  In a <td />, if the content inside is empty then it is not possible to set the width and height to 100% to fit the <td /> size.
  The consequence is a <a /> inside with size 0 when content is empty, in this case we cannot click to the cell to follow the href.
  To bypass this behavior, if there is no value to display, set a random value (eg: 'Not set'), and hide it with 'visibiliy: hidden'.
 */
const TableCellLink = (props: TableCellLinkProps) => (
  <TableCell {...props} sx={{ ...props.sx, padding: 0 }}>
    <Link
      style={{
        color: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block',
      }}
      to={props.to}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          visibility: props.children ? 'visible' : 'hidden',
          padding: 1,
        }}
      >
        {props.children ?? 'Not set'}
      </Box>
    </Link>
  </TableCell>
);

type TableRowLinkProps = TableRowProps & {
  to: string;
  children: ReactElement<TableCellProps, typeof TableCell>[];
};

export const TableRowLink = (props: TableRowLinkProps) => {
  const tableCellsProps = React.Children.map(
    props.children,
    tableCell => tableCell.props,
  );
  const theme = useTheme();

  return (
    <TableRow
      sx={{
        '&:hover': {
          background: theme.palette.divider,
          cursor: 'pointer',
          transition:
            'background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      }}
    >
      {tableCellsProps.map((_, idx) => {
        const newCellProps = { ..._, children: undefined };
        return (
          <TableCellLink key={idx} to={props.to} {...newCellProps}>
            {_.children}
          </TableCellLink>
        );
      })}
    </TableRow>
  );
};
