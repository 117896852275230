import { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CircleIcon from '@mui/icons-material/Circle';
import AvatarUser from '../Avatar/AvatarUser';
import ButtonLink from '../Button/ButtonLink';
import ButtonLinkUser from '../Button/ButtonLinkUser';
import { DashboardIcon } from '../Icon/DashboardIcon';
import { ProjectIcon } from '../Icon/ProjectIcon';
import { PersonIcon } from '../Icon/PersonIcon';
import { LibraryIcon } from '../Icon/LibraryIcon';
import { useUserContext } from '../Context/UserContext';
import { Disconnect } from '../Icon/Disconnect';
import { WalletIcon } from '../Icon/WalletIcon';

import { paletteBlue } from 'static/themes/blue/palette';
import { paletteDark } from 'static/themes/dark/palette';
import { paletteLight } from 'static/themes/light/palette';
import { useAppContext } from 'components/Context/AppContext';
import useMediaQuery from '@mui/material/useMediaQuery';

export type SidebarProps = {
  minified?: boolean;
};

const SidebarContent = (props: SidebarProps) => {
  const { pathname } = useLocation();
  const auth = useAuth();
  const theme = useTheme();

  const userContext = useUserContext();
  const appContext = useAppContext();

  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

  const navs = [
    {
      label: 'Tableau de bord',
      icon: <DashboardIcon />,
      to: 'dashboard',
      match: ['/', 'dashboard'],
    },
    {
      label: 'Projets',
      icon: <ProjectIcon />,
      to: 'projets',
      match: 'projets/*',
    },
    {
      label: 'Personnes',
      icon: <PersonIcon />,
      to: 'personnes',
      match: 'personnes/*',
    },
    {
      label: 'Portefeuille',
      icon: <WalletIcon />,
      to: 'portefeuille',
      match: 'portefeuille/*',
    },
    {
      label: 'Bibliothèque',
      icon: <LibraryIcon />,
      to: 'offres',
      match: 'offres/*',
    },
  ];

  const themes = [
    {
      label: 'Bleu',
      code: 'blue',
      color: paletteBlue.background?.default,
    },
    {
      label: 'Sombre',
      code: 'dark',
      color: paletteDark.background?.default,
    },
    {
      label: 'Clair',
      code: 'light',
      color: paletteLight.background?.default,
    },
  ];

  const [anchorThemeButton, setAnchorThemeButton] =
    useState<null | HTMLElement>(null);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems={props.minified ? 'center' : 'stretch'}
      sx={{
        height: '100%',
      }}
    >
      <Stack direction="column" spacing={2}>
        {!props.minified && (
          <ButtonLinkUser
            user={userContext.data.user}
            agency={userContext.data.agency}
            to="compte"
            selected={matchPath('compte/*', pathname) !== null}
            minified={props.minified}
          />
        )}

        <Stack direction="column" spacing={props.minified ? 0.5 : 0}>
          {navs.map(_ => (
            <ButtonLink
              {..._}
              key={_.to}
              selected={
                Array.isArray(_.match)
                  ? !!_.match.find(m => matchPath(m, pathname) !== null)
                  : matchPath(_.match, pathname) !== null
              }
              minified={props.minified}
            />
          ))}
        </Stack>
      </Stack>

      <Stack direction="column" spacing={1}>
        <ButtonLink
          label="Thème"
          icon={
            <CircleIcon
              sx={{
                borderRadius: 32,
                border: 1,
                borderColor: theme.palette.text.primary,
                color: theme.palette.background.default,
              }}
            />
          }
          iconPosition="end"
          onClick={e => setAnchorThemeButton(e.currentTarget)}
          minified={props.minified}
        />
        <Popper
          open={anchorThemeButton !== null}
          anchorEl={anchorThemeButton}
          placement={screenSizeUpSM ? 'right-end' : 'top'}
          sx={{
            minWidth: {
              sm: 260,
              xs: '100%',
            },
            zIndex: 10000,
          }}
        >
          <ClickAwayListener onClickAway={() => setAnchorThemeButton(null)}>
            <Paper
              sx={{
                borderRadius: 2,
              }}
            >
              <List dense>
                {themes.map(_ => {
                  const selected = appContext.data.theme === _.code;
                  return (
                    <ListItemButton
                      key={_.code}
                      selected={selected}
                      onClick={() => {
                        setAnchorThemeButton(null);
                        appContext.setData(prev => {
                          return { ...prev, theme: _.code };
                        });
                      }}
                    >
                      <Stack
                        sx={{ width: '100%' }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography fontWeight={selected ? 700 : 500}>
                          {_.label}
                        </Typography>
                        <CircleIcon
                          sx={{
                            borderRadius: 32,
                            border: 1,
                            borderColor: theme.palette.text.secondary,
                            color: _.color,
                          }}
                        />
                      </Stack>
                    </ListItemButton>
                  );
                })}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>

        {props.minified && (
          <AvatarUser
            user={userContext.data.user}
            selected={matchPath('compte/*', pathname) !== null}
            minified={props.minified}
          />
        )}

        <ButtonLink
          label="Déconnexion"
          icon={<Disconnect />}
          iconPosition="end"
          color={theme.palette.error.main}
          onClick={() =>
            auth?.signoutRedirect({ id_token_hint: auth.user?.id_token })
          }
          minified={props.minified}
        />
      </Stack>
    </Stack>
  );
};

export default SidebarContent;
