import { Icon } from '.';
import { IconProps } from './settings';

export const ViewIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill={props.fill || 'none'}
      >
        <path
          stroke={props.color}
          strokeWidth={2}
          d="M26.633 12.519c1.347 1.417 1.347 3.545 0 4.962C23.95 20.304 19.68 23.75 15 23.75c-4.68 0-8.95-3.446-11.633-6.269-1.347-1.417-1.347-3.545 0-4.962C6.05 9.696 10.32 6.25 15 6.25c4.68 0 8.95 3.446 11.633 6.269Z"
        />
        <circle cx={15} cy={15} r={5.75} stroke={props.color} strokeWidth={2} />
        <circle
          cx={15}
          cy={15}
          r={2}
          fill={props.color}
          stroke={props.color}
          strokeWidth={2}
        />
      </svg>
    </Icon>
  );
};
