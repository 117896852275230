import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  Person,
  PersonRequest,
  PersonSearchCriteriaRequest,
} from 'models/oav/Person.models';
import {
  fetchPerson,
  fetchPersonList,
  postPerson,
  putPerson,
} from 'api/oav/Person.api';
import { Pagination } from 'models/Page.models';
import { useAccessToken } from 'utils/api/api';

// 30 sec.
const defaultStaleTime = 30000;

export const usePutPersonMutation = (
  onSuccess?: (person: Person) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: (params: { person: PersonRequest; uuid: string }) =>
      putPerson(params.person, params.uuid, accessToken),
    onSuccess: (person: Person) => {
      // Save query data in order to avoid further fetch on this person
      client.setQueryData(['person', person.id], () => person);
      onSuccess?.(person);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const usePostPersonMutation = (
  onSuccess?: (person: Person) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: (person: PersonRequest) => postPerson(person, accessToken),
    onSuccess: (person: Person) => {
      // Save query data in order to avoid further fetch on this person
      client.setQueryData(['person', person.id], () => person);
      onSuccess?.(person);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const personGetByIdOptions = (uuid: string) => {
  const accessToken = useAccessToken();

  return queryOptions({
    queryKey: ['person', uuid],
    queryFn: () => fetchPerson(uuid, accessToken),
    gcTime: defaultStaleTime,
    staleTime: defaultStaleTime,
  });
};

export const personGetListOptions = (
  pagination: Pagination,
  criteria: PersonSearchCriteriaRequest,
) => {
  const accessToken = useAccessToken();

  return queryOptions({
    queryKey: ['person', pagination.page, pagination.size, criteria],
    queryFn: () => fetchPersonList(pagination, criteria, accessToken),
    gcTime: defaultStaleTime,
    staleTime: defaultStaleTime,
  });
};
