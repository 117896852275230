import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { Offer } from 'models/referentiels/Offer.model';
import { Formula } from 'models/referentiels/Formula.model';

export type CardOfferLinkProps = {
  offer: Offer;
  formula?: Formula;
};

const CardOfferLink = (props: CardOfferLinkProps) => {
  const theme = useTheme();

  // TODO : Add Link / onClick

  return (
    <Card
      sx={{
        borderRadius: 2,
        px: 1,
        py: 0,
        background: 'none',
        cursor: 'pointer',
        ':hover': {
          background: theme.palette.primary.main + '20',
        },
      }}
    >
      <Typography component="div" variant="caption">
        {props.offer.label}
      </Typography>

      {/* Formule */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography
          variant="caption"
          sx={{
            color: props.formula?.color || theme.palette.text.primary,
          }}
        >
          {props.formula?.label || <i>Aucune formule</i>}
        </Typography>

        {/* {props.formula?.pricing?.monthly && (
          <Typography variant="caption">
            <pre style={{ display: 'inline' }}>
              {props.formula?.pricing?.monthly} €/mois
            </pre>
          </Typography>
        )} */}
      </Stack>
    </Card>
  );
};

export default CardOfferLink;
