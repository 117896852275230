//@ts-ignore
import AnimatedNumber from 'animated-number-react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import { Formula } from 'models/referentiels/Formula.model';
import { TrfPricing } from 'models/referentiels/Tarification.models';

export type CardFormulaProps = {
  formula: Formula;
  pricing?: TrfPricing;
  selected?: boolean;
  disabled?: boolean;
  recommended?: boolean;
  actions?: JSX.Element;
  onClick?: () => void;
};

const CardFormula = (props: CardFormulaProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        position: 'relative',
        overflow: 'visible',
        height: '100%',
        p: 1,
        border: 2,
        borderColor: props.selected
          ? theme.palette.primary.main
          : theme.palette.divider,
        cursor: props.onClick && !props.disabled ? 'pointer' : 'default',
        transition: 'transform 150ms ease-in-out',
        boxShadow: theme.shadows[3],
        ':hover': {
          transform:
            props.onClick && !props.disabled ? 'translateY(-6px)' : 'none',
        },
      }}
      onClick={() => !props.disabled && props.onClick?.()}
    >
      {props.recommended && (
        <Box
          sx={{
            position: 'absolute',
            top: -20,
            fontWeight: 700,
            width: 'calc(100% - 16px)',
            textAlign: 'center',
          }}
        >
          <Chip color="primary" label="Notre conseil" size="small" />
        </Box>
      )}

      <Grid
        container
        spacing={{
          md: 1,
          xs: 2,
        }}
        alignItems="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid item xs>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            {props.onClick && (
              <Checkbox checked={props.selected} disabled={props.disabled} />
            )}
            <Typography
              sx={{
                flexGrow: 2,
                color: props.formula.color || theme.palette.text.primary,
              }}
              noWrap
            >
              {props.formula.label}
            </Typography>
          </Stack>
          <Typography variant="caption">{props.formula.description}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Divider />
        </Grid>

        <Grid
          item
          xs
          sx={{
            textAlign: {
              md: 'left',
              xs: 'right',
            },
          }}
        >
          <Typography variant="body1">
            <pre style={{ display: 'inline' }}>
              {props.pricing ? (
                <AnimatedNumber
                  value={props.pricing?.perMonth}
                  duration={200}
                  easing="linear"
                  formatValue={(value: number) =>
                    value.toLocaleString('fr-FR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                />
              ) : (
                '-'
              )}
            </pre>{' '}
            €/mois
          </Typography>

          <Typography variant="caption">
            Soit&nbsp;
            <pre style={{ display: 'inline' }}>
              {props.pricing ? (
                <AnimatedNumber
                  value={props.pricing?.perYear}
                  duration={200}
                  easing="linear"
                  formatValue={(value: number) =>
                    value.toLocaleString('fr-FR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                />
              ) : (
                '-'
              )}
            </pre>{' '}
            €/an
          </Typography>
        </Grid>

        {props.actions && (
          <>
            <Grid
              item
              xs="auto"
              sx={{
                height: '80%',
                display: {
                  md: 'none',
                  xs: 'flex',
                },
              }}
            >
              <Divider orientation="vertical" />
            </Grid>

            <Grid item xs="auto" onClick={e => e.stopPropagation()}>
              {props.actions}
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CardFormula;
