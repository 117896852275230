import React from 'react';
import { Outlet } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import AppBar from 'components/AppBar';
import Typography from '@mui/material/Typography';
import { AccountMenu } from '../menu';
import { appName } from 'App';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import TabMenu from 'components/Tabs/TabMenu';

const AccountMeLayout: React.FC = () => {
  document.title = `Compte - ${appName}`;

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h2">Votre profil</Typography>
        </Stack>
      </AppBar>
      <Box display="flex" justifyContent="center">
        <Card variant="outlined" sx={{ p: 2, backgroundColor: 'transparent' }}>
          <TabMenu buttons={AccountMenu} />
          <Box sx={{ p: 2, mt: 3 }}>
            <Outlet />
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default AccountMeLayout;
