import { Icon } from '.';
import { IconProps } from './settings';

export const DocumentMajorityIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'80'}
        height={'80'}
        fill="none"
        viewBox="0 0 80 80"
      >
        <rect
          width="66.3"
          height="41"
          x="6.85"
          y="19.5"
          stroke={props.color}
          rx="3.5"
        ></rect>
        <mask id="path-2-inside-1_2031_19023" fill="#fff">
          <path
            fillRule="evenodd"
            d="M24.786 39.724c3.657 0 6.622-2.849 6.622-6.362 0-3.514-2.965-6.362-6.622-6.362-3.658 0-6.623 2.848-6.623 6.362 0 3.513 2.965 6.362 6.623 6.362zm-.036.552c-5.744 0-10.4 4.656-10.4 10.4V53h20.8v-2.324c0-5.744-4.656-10.4-10.4-10.4z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill={props.color}
          d="M14.35 53h-1v1h1v-1zm20.8 0v1h1v-1h-1zm-4.742-19.638c0 2.924-2.479 5.362-5.622 5.362v2c4.171 0 7.622-3.259 7.622-7.362h-2zM24.786 28c3.143 0 5.622 2.438 5.622 5.362h2c0-4.104-3.45-7.362-7.622-7.362v2zm-5.623 5.362c0-2.924 2.479-5.362 5.623-5.362v-2c-4.172 0-7.623 3.259-7.623 7.362h2zm5.623 5.362c-3.144 0-5.623-2.438-5.623-5.362h-2c0 4.103 3.45 7.362 7.623 7.362v-2zM15.35 50.676a9.4 9.4 0 019.4-9.4v-2c-6.296 0-11.4 5.104-11.4 11.4h2zm0 2.324v-2.324h-2V53h2zm19.8-1h-20.8v2h20.8v-2zm-1-1.324V53h2v-2.324h-2zm-9.4-9.4a9.4 9.4 0 019.4 9.4h2c0-6.296-5.104-11.4-11.4-11.4v2z"
          mask="url(#path-2-inside-1_2031_19023)"
        ></path>
        <path
          fill={props.color}
          d="M42.326 40.87a.53.53 0 01-.392-.154.514.514 0 01-.154-.378c0-.15.051-.27.154-.364a.53.53 0 01.392-.154h5.74a.53.53 0 01.392.154.514.514 0 01.154.378c0 .15-.051.275-.154.378a.56.56 0 01-.392.14h-5.74zm2.856 2.926a.618.618 0 01-.434-.168.57.57 0 01-.168-.42v-5.782c0-.177.056-.322.168-.434a.636.636 0 01.448-.168c.177 0 .317.056.42.168a.587.587 0 01.168.434v5.768a.587.587 0 01-.168.434.587.587 0 01-.434.168zM51.684 45a.57.57 0 01-.42-.168.553.553 0 01-.168-.406V36.46l.168.196-1.582 1.092a.548.548 0 01-.322.098.553.553 0 01-.406-.168.585.585 0 01-.168-.406c0-.205.098-.369.294-.49l2.226-1.47a.78.78 0 01.224-.098.606.606 0 01.224-.014c.168 0 .299.056.392.168a.531.531 0 01.154.392v8.666c0 .159-.06.294-.182.406a.587.587 0 01-.434.168zm7.238-4.942l-.028-.196c.327.14.62.331.882.574.261.243.467.523.616.84.159.308.238.644.238 1.008 0 .504-.14.97-.42 1.4-.27.42-.644.756-1.12 1.008-.467.252-.99.378-1.568.378a3.247 3.247 0 01-1.568-.378 3.077 3.077 0 01-1.12-1.008 2.576 2.576 0 01-.406-1.4c0-.42.084-.789.252-1.106a2.96 2.96 0 01.658-.812c.27-.224.55-.401.84-.532l-.07.224a2.612 2.612 0 01-.7-.448 2.617 2.617 0 01-.532-.728c-.14-.29-.21-.616-.21-.98 0-.504.126-.961.378-1.372a2.65 2.65 0 011.036-.966 2.93 2.93 0 011.442-.364c.532 0 1.013.121 1.442.364.43.233.77.555 1.022.966.261.41.392.868.392 1.372 0 .383-.075.719-.224 1.008-.15.28-.336.518-.56.714a2.654 2.654 0 01-.672.434zM55.8 37.916c0 .336.075.63.224.882.159.252.369.453.63.602.27.14.56.21.868.21.317 0 .602-.07.854-.21.261-.15.471-.35.63-.602a1.62 1.62 0 00.238-.882c0-.485-.163-.882-.49-1.19-.317-.317-.728-.476-1.232-.476s-.92.159-1.246.476c-.317.308-.476.705-.476 1.19zm1.722 6.104c.579 0 1.05-.168 1.414-.504a1.61 1.61 0 00.56-1.246c0-.345-.089-.649-.266-.91a1.796 1.796 0 00-.714-.63 2.085 2.085 0 00-.994-.238c-.355 0-.681.08-.98.238-.299.15-.537.36-.714.63a1.582 1.582 0 00-.266.91c0 .495.182.91.546 1.246.373.336.845.504 1.414.504z"
        ></path>
      </svg>
    </Icon>
  );
};
