import { Icon } from '.';
import { IconProps } from './settings';

export const ArrowCircleRightIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="15"
          cy="15"
          r="14"
          transform="rotate(180 15 15)"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2554 7.87146C16.9104 7.51095 16.3509 7.51095 16.0059 7.87146C15.6608 8.23197 15.6608 8.81648 16.0059 9.17699L20.9167 14.0668L6.01037 14.0668C5.30942 14.0668 4.74119 14.4974 4.74119 15.0285C4.74119 15.5596 5.30942 15.9902 6.01037 15.9902L21.2066 15.9902L16.0059 21.4239C15.6608 21.7844 15.6608 22.3689 16.0059 22.7294C16.3509 23.0899 16.9104 23.0899 17.2554 22.7294L23.7411 15.9532C24.0861 15.5927 24.0861 15.0082 23.7411 14.6477L17.2554 7.87146Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
