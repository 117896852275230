import { queryOptions } from '@tanstack/react-query';
import { useAccessToken } from 'utils/api/api';
import { fetchWarrantyAcronymAll } from './Warranty.api';
import { WarrantyAcronym } from 'models/referentiels/Warranty.model';

export const warrantyQueries = {
  getAcronymAll: () => warrantyAcronymGetAllOptions(),
  getAcronymMap: () => warrantyAcronymGetMapOptions(),
};

export const warrantyAcronymGetAllOptions = () => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['warranty', 'acronyms'],
    queryFn: () => {
      return fetchWarrantyAcronymAll(token);
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export const warrantyAcronymGetMapOptions = () => {
  const query = warrantyAcronymGetAllOptions();
  return {
    ...query,
    select: (data: WarrantyAcronym[]) => {
      return data.reduce(
        (acc: Record<string, WarrantyAcronym>, a: WarrantyAcronym) => {
          acc[a.acronym] = a;
          return acc;
        },
        {},
      );
    },
  };
};
