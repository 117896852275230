export const buildParams = (
  params: object,
  baseLabel?: string,
): [string, string][] | undefined => {
  const p: [string, string][] = [];

  for (const [key, value] of Object.entries(params)) {
    const isArray: boolean = Array.isArray(value);

    if (!value || (isArray && value.length === 0)) continue;

    const f = isArray ? value.join(',') : value;
    p.push([`${baseLabel ? `${baseLabel}.` : ''}${key}`, f]);
  }

  return p.length === 0 ? undefined : p;
};

export const addParams = (
  urlParams: URLSearchParams,
  params?: object,
  baseLabel?: string,
) => {
  if (!params) {
    return;
  }
  buildParams(params, baseLabel)?.forEach(([key, value]) => {
    urlParams.append(key, value);
  });
};
