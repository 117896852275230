import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {
  NeededSupportingDocument,
  SupportingDocument,
} from 'models/oav/SupportingDocument.models';
import ButtonClose from 'components/Button/ButtonClose';
import { DropzoneDocument } from 'components/Dropzone/DropzoneDocument';
import { useUploadSupportingDocuments } from 'utils/project/supportingDocuments';
import CardSupportingDocuments from 'content/project/supportingDocument/CardSupportingDocuments';
import { InfoIcon } from 'components/Icon/InfoIcon';
import PreviewDocument from 'content/project/supportingDocument/PreviewDocument';

interface DialogChildMajorityProps {
  neededDocuments: NeededSupportingDocument;
  onClose: () => void;
  projectId: string;
  open: boolean;
}

const IdType = ({ children, info }: { children: ReactNode; info: string }) => {
  const theme = useTheme();
  return (
    <li style={{ marginTop: theme.spacing(0.5) }}>
      <Stack direction="row" gap={1} alignItems="center" justifyContent="start">
        {children}
        <Tooltip title={info} placement="right" arrow>
          <Box
            sx={{
              ml: 0.5,
              width: '20px',
              height: '20px',
            }}
          >
            <InfoIcon color={theme.palette.text.disabled} size="small" />
          </Box>
        </Tooltip>
      </Stack>
    </li>
  );
};

const i1 = 'Pour un enfant majeur à charge fiscalement, de moins de 21 ans';
const i2 =
  'Pour un enfant majeur à charge fiscalement, de moins de 28 ans dans cette situation';
const i3 =
  'Pour un enfant majeur à charge fiscalement, de 21 ans ou plus et en situation de handicap';

type DialogChildMajorityContentProps = DialogChildMajorityProps & {
  dropzone: ReturnType<typeof useUploadSupportingDocuments>;
  previewDocument?: SupportingDocument;
  setPreviewDocument: Dispatch<SetStateAction<SupportingDocument | undefined>>;
};

const DialogChildMajorityContent: FC<DialogChildMajorityContentProps> = ({
  dropzone,
  onClose,
  projectId,
  previewDocument,
  setPreviewDocument,
  neededDocuments,
}) => {
  const { actionPending, files, onDelete, onDrop } = dropzone;
  const theme = useTheme();

  return (
    <>
      <DialogTitle>Situation enfant majeur</DialogTitle>
      <ButtonClose
        disabled={actionPending}
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      />
      <DialogContent sx={{ width: '100%' }}>
        <Grid container columnSpacing={4} sx={{ height: '100%' }}>
          {previewDocument && (
            <Grid
              item
              xs={8}
              sx={{
                height: '100%',
              }}
            >
              <PreviewDocument
                document={previewDocument}
                onClose={() => setPreviewDocument(undefined)}
              />
            </Grid>
          )}
          <Grid
            item
            xs={previewDocument ? 4 : 12}
            sx={{ height: '100%', overflow: 'auto' }}
          >
            <Typography variant="body1" sx={{ mb: 1 }}>
              Veuillez importer le ou les justificatifs de situation, en cours
              de validité, de chaque enfant majeur à enregistrer pour la
              souscription :
            </Typography>
            <ul style={{ marginBottom: theme.spacing(2) }}>
              <IdType info={i1}>Dernier avis d&apos;imposition</IdType>
              <IdType info={i2}>Certificat de scolarité</IdType>
              <IdType info={i2}>
                Contrat d&apos;alternance ou d&apos;apprentissage
              </IdType>
              <IdType info={i2}>Attestation de service civique</IdType>
              <IdType info={i2}>Attestation chômage</IdType>
              <IdType info={i3}>
                Carte d&apos;invalidité ou mobilité inclusion
              </IdType>
            </ul>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '260px',
                }}
              >
                <DropzoneDocument
                  maxFiles={neededDocuments.max}
                  onDrop={onDrop}
                  nbFiles={files.length}
                  label={
                    <>
                      Glissez et déposez <strong>le justificatif ici</strong>
                    </>
                  }
                  helper="Taille maximale : 10Mo - Formats acceptés : .pdf, .jpeg, .jpg, .png, .bmp, .webp"
                />
              </Grid>
              {files.map((_, index) => (
                <Grid key={index} item xs={12}>
                  <CardSupportingDocuments
                    projectId={projectId}
                    file={_}
                    type={neededDocuments.type}
                    onDelete={onDelete(_)}
                    onPreview={setPreviewDocument}
                    viewing={_.id ? previewDocument?.id === _.id : false}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              onClick={onClose}
              disabled={actionPending}
              sx={{
                px: 4,
              }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="gradient"
              color="primary"
              disabled={actionPending}
              onClick={onClose}
              sx={{
                px: 4,
              }}
            >
              Valider
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
const DialogChildMajority: React.FC<DialogChildMajorityProps> = props => {
  const { neededDocuments, onClose, projectId, open } = props;
  const dropzone = useUploadSupportingDocuments({ neededDocuments, projectId });

  const [previewDocument, setPreviewDocument] = useState<
    SupportingDocument | undefined
  >();
  useEffect(() => {
    if (!open) {
      setPreviewDocument(undefined);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={dropzone.actionPending ? undefined : onClose}
      maxWidth={previewDocument ? 'xl' : 'md'}
      fullWidth
      sx={{
        height: previewDocument ? '100%' : undefined,
      }}
      PaperProps={{ sx: { height: previewDocument ? '100%' : undefined } }}
    >
      <DialogChildMajorityContent
        {...props}
        setPreviewDocument={setPreviewDocument}
        previewDocument={previewDocument}
        dropzone={dropzone}
      />
    </Dialog>
  );
};

export default DialogChildMajority;
