export interface Risk {
  id: string;
  code: string;
  label: string;
  description?: string;
}

// TODO : Improve me.
const riskColor = [
  {
    code: 'SANTE',
    color: {
      main: '#98AFFF',
      secondary: '#B880DB',
    },
  },
  {
    code: 'PREVOYANCE',
    color: {
      main: '#52E09C',
      secondary: '#59CCD3',
    },
  },
];

export const getRiskColor = (code: string) => {
  return riskColor.find(_ => _.code === code)?.color;
};
