import React from 'react';

import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';

import { Offer } from 'models/referentiels/Offer.model';
import { getRiskColor } from 'models/referentiels/Risk.model';

interface SmallOfferCardProps {
  offer: Offer;
}

export const CardOfferSmall: React.FC<SmallOfferCardProps> = ({ offer }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        p: 2,
        border: 2,
        borderColor: theme.palette.divider,
      }}
    >
      <Stack
        gap={1}
        alignItems="space-between"
        sx={{
          height: '100%',
        }}
      >
        <Stack gap={1} flexGrow={1}>
          <Typography variant="body1">
            <Stack direction="row" gap={1} flexWrap="wrap-reverse" useFlexGap>
              <b>{offer?.label}</b>

              {offer?.risks?.map(_ => {
                const color = getRiskColor(_.code);

                return (
                  <Chip
                    key={_.id}
                    label={_.label}
                    size="small"
                    sx={{
                      fontSize: '0.9rem',
                      fontWeight: 700,
                      color: color
                        ? theme.palette.background.default
                        : theme.palette.text.primary,
                      background: color
                        ? `linear-gradient(270deg, ${color.main} 0%, ${color.secondary} 100%)`
                        : theme.palette.background.default,
                    }}
                  />
                );
              })}
            </Stack>
          </Typography>
          {offer?.description && (
            <Typography variant="body2">{offer.description}</Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
