import { ToggleButton, ToggleButtonProps } from '@mui/material';

export const ToggleButtonSplit = (props: ToggleButtonProps) => {
  return (
    <ToggleButton
      {...props}
      sx={{
        ...props.sx,
        paddingX: 2,
        paddingY: 1,
        textTransform: 'none',
        fontWeight: 'normal',
        ':hover': {
          background: '#00000030',
        },
      }}
    />
  );
};
