import { ChevronBottomIcon } from 'components/Icon/ChevronBottomIcon';
import { Component } from 'react';

export type Warranty = {
  id: string;
  order: number;
  label: string;
  formulas: WarrantyFormula[];
};

export type WarrantySection = {
  order: number;
  sectionTitle: string;
  comment?: string;
  subSections: WarrantySubSection[];
};

export type WarrantySubSection = {
  order: number;
  subSectionTitle: string;
  warranties: Warranty[];
};

export type WarrantyFormula = {
  formulaCode: string;
  amounts?: WarrantyAmount[];
};

export type WarrantyAmount = {
  order: number;
  amount: number;
  amountType: WarrantyAmountType;
  amountAcronym?: string;
  description?: string;
  isMR: boolean;
};

export type WarrantyAcronym = {
  id: number;
  acronym: string;
  description: string;
};

export type WarrantyAcronymMap = { [key: string]: WarrantyAcronym };

export enum WarrantyAmountType {
  'CURRENCY' = 'CURRENCY',
  'PERCENTAGE' = 'PERCENTAGE',
}

export const warrantyAmountTypeLabel: {
  [key in WarrantyAmountType]: { label: string };
} = {
  [WarrantyAmountType.CURRENCY]: { label: '€' },
  [WarrantyAmountType.PERCENTAGE]: { label: '%' },
};
