import { RestError } from 'errors/RestError';
import { useMutation } from '@tanstack/react-query';
import { useAccessToken } from 'utils/api/api';
import { Estimation, EstimationRequest } from 'models/oav/Estimation.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const createEstimation = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: EstimationRequest,
): Promise<Estimation> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/estimation`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const useCreateEstimationMutation = (
  onSuccess?: (
    data: Estimation,
    variables: {
      projectId: string;
      subscriptionId: string;
      payload: EstimationRequest;
    },
  ) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  return useMutation({
    mutationFn: (params: {
      projectId: string;
      subscriptionId: string;
      payload: EstimationRequest;
    }) =>
      createEstimation(
        accessToken,
        params.projectId,
        params.subscriptionId,
        params.payload,
      ),
    onSuccess: (estimation: Estimation, variables) => {
      onSuccess?.(estimation, variables);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};
