import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Pagination } from 'models/Page.models';
import { PersonSearchCriteriaRequest } from 'models/oav/Person.models';

export type PersonTabKeys = 'identity' | 'contact-info';

export type PersonContextData = {
  selectedTab: PersonTabKeys;
  setSelectedTab: Dispatch<SetStateAction<PersonTabKeys>>;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  searchCriteria: { value: PersonSearchCriteriaRequest; errors: any };
  setSearchCriteria: Dispatch<
    SetStateAction<PersonContextData['searchCriteria']>
  >;
};

export const PersonContext = createContext<PersonContextData>({
  setSelectedTab: () => {},
  selectedTab: 'identity',
  pagination: { page: 0, size: 0 },
  setPagination: () => {},
  searchCriteria: {
    value: {
      email: '',
      birthdate: null,
      firstname: '',
      lastname: '',
    },
    errors: {},
  },
  setSearchCriteria: () => {},
});

export const usePersonContext = () => {
  return useContext(PersonContext);
};
