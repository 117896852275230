import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

export const TableLinearLoading = () => (
  <LinearProgress
    sx={{
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: 0,
      left: 0,
      '&.MuiLinearProgress-bar': {
        transition: 'transform 0.3s linear',
      },
    }}
  />
);
