import { Icon } from '.';
import { IconProps } from './settings';

export const MenuIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 18L20 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        ></path>
        <path
          d="M4 12L20 12"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        ></path>
        <path
          d="M4 6L20 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        ></path>
      </svg>
    </Icon>
  );
};
