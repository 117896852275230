import { IconProps } from './settings';
import { Icon } from '.';

export const NeedServiceIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_30_4502" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.974 24.9101C35.8109 24.6475 35.7807 24.325 35.8775 24.0314C36.3003 22.7486 36.5263 21.3966 36.5263 20C36.5263 11.7157 28.5735 5 18.7632 5C8.95284 5 1 11.7157 1 20C1 28.2843 8.95284 35 18.7632 35C22.8992 35 26.705 33.8063 29.724 31.8047C29.9022 31.6865 30.112 31.6239 30.3258 31.6307L38.4806 31.893C39.2788 31.9186 39.7836 31.0444 39.3622 30.3659L35.974 24.9101Z"
          />
        </mask>
        <path
          d="M29.724 31.8047L30.8292 33.4715L29.724 31.8047ZM30.3258 31.6307L30.3901 29.6318L30.3258 31.6307ZM38.4806 31.893L38.5448 29.894L38.4806 31.893ZM35.8775 24.0314L33.978 23.4052L35.8775 24.0314ZM35.974 24.9101L34.2749 25.9653L35.974 24.9101ZM34.5263 20C34.5263 21.1788 34.3358 22.3196 33.978 23.4052L37.7769 24.6575C38.2647 23.1776 38.5263 21.6144 38.5263 20H34.5263ZM18.7632 7C27.7992 7 34.5263 13.1239 34.5263 20H38.5263C38.5263 10.3076 29.3478 3 18.7632 3V7ZM3 20C3 13.1239 9.72713 7 18.7632 7V3C8.17854 3 -1 10.3076 -1 20H3ZM18.7632 33C9.72713 33 3 26.8761 3 20H-1C-1 29.6924 8.17854 37 18.7632 37V33ZM28.6187 30.1378C25.9363 31.9163 22.5168 33 18.7632 33V37C23.2816 37 27.4737 35.6963 30.8292 33.4715L28.6187 30.1378ZM38.5448 29.894L30.3901 29.6318L30.2615 33.6297L38.4163 33.8919L38.5448 29.894ZM34.2749 25.9653L37.6632 31.4211L41.0612 29.3108L37.673 23.855L34.2749 25.9653ZM30.8292 33.4715C30.6777 33.572 30.4816 33.6368 30.2615 33.6297L30.3901 29.6318C29.7425 29.6109 29.1268 29.8009 28.6187 30.1378L30.8292 33.4715ZM38.4163 33.8919C40.811 33.9689 42.3253 31.3461 41.0612 29.3108L37.6632 31.4211C37.2418 30.7426 37.7466 29.8683 38.5448 29.894L38.4163 33.8919ZM33.978 23.4052C33.7085 24.2227 33.7786 25.166 34.2749 25.9653L37.673 23.855C37.8431 24.129 37.8528 24.4272 37.7769 24.6575L33.978 23.4052Z"
          fill="currentColor"
          mask="url(#path-1-inside-1_30_4502)"
        />
      </svg>
    </Icon>
  );
};
