import { queryOptions } from '@tanstack/react-query';

import {
  fetchProjectById,
  fetchProjectOffers,
  fetchProjectsList,
} from 'api/oav/Project.api';

import { Pagination } from 'models/Page.models';
import { PageResponse } from 'models/api.model';
import { Offer } from 'models/referentiels/Offer.model';
import { useAccessToken } from 'utils/api/api';
import { ProjectSearchCriteriaRequest } from 'models/oav/Project.models';

// 30 sec.
const defaultStaleTime = 30000;

export const projectQueries = {
  getPage: (
    pagination?: Pagination,
    criteria?: ProjectSearchCriteriaRequest,
    sort?: string[],
  ) => projectGetPageOptions(pagination, criteria, sort),
  getById: (id: string) => projectGetByIdOptions(id),
  getByIdOffers: (id: string) => projectGetByIdOffersOptions(id),
  getByIdOffersMap: (id: string) => projectGetByIdOffersMapOptions(id),
};

export const projectGetPageOptions = (
  pagination?: Pagination,
  criteria?: ProjectSearchCriteriaRequest,
  sort?: string[],
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', pagination, criteria, sort],
    queryFn: () => fetchProjectsList(token, pagination, criteria, sort),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

export const projectGetByIdOptions = (id: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', id],
    queryFn: () => fetchProjectById(token, id),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};

export const projectGetByIdOffersOptions = (id: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', id, 'offers'],
    queryFn: () => fetchProjectOffers(token, id),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
    select: data => data.content,
  });
};

export const projectGetByIdOffersMapOptions = (id: string) => {
  const query = projectGetByIdOffersOptions(id);
  return {
    ...query,
    select: (data: PageResponse<Offer>) => {
      return data.content.reduce((acc: Record<string, Offer>, offer: Offer) => {
        acc[offer.code] = offer;
        return acc;
      }, {});
    },
  };
};
