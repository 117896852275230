import { Icon } from '.';
import { IconProps } from './settings';

export const CheckCircleOutlinedBlankIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="currentColor"
        />
      </svg>
    </Icon>
  );
};
