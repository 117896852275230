import { TextFieldProps } from '@mui/material/TextField';
import FormattedTextField from './FormattedTextField';
import { nniRegex } from 'utils/fields/fields';

export const NNITextField = (props: TextFieldProps) => (
  <FormattedTextField
    {...props}
    groupsRegex={nniRegex}
    removeRegex={/\D/g}
    separator=" "
    inputProps={{
      ...props?.inputProps,
      minLength: 21,
      maxLength: 21,
    }}
  />
);
