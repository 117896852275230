import React, { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import { CountryData } from 'react-phone-input-material-ui';
import { RestError } from 'errors/RestError';
import CardError from 'components/Card/CardError';
import { useAppContext } from 'components/Context/AppContext';
import { useProjectContext } from 'components/Context/ProjectContext';
import TextField from 'components/TextField/TextField';
import TextFieldPhone, {
  countryCodeEnabled,
} from 'components/TextField/TextFieldPhone/TextFieldPhone';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';

import { updateProjectStepContact } from 'api/oav/ProjectStep.api';
import { projectQueries } from 'api/oav/ProjectQuery.api';

import { ApiResponse } from 'models/api.model';
import {
  Civility,
  civilityLabels,
  Person,
  PersonContactRequest,
} from 'models/oav/Person.models';
import {
  getNextStep,
  getPreviousStep,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import {
  Project,
  ProjectStepContact,
  ProjectStepContactPostErrors,
} from 'models/oav/Project.models';

import { useAccessToken } from 'utils/api/api';
import { emptyStringToUndefined } from 'utils/fields/fields';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { messageFieldRequired } from 'utils/validation/message';
import {
  formatPhoneRequest,
  Phone,
  yupFieldPhone,
} from 'utils/validation/yupPerson';

export interface FormContactValues {
  civility?: Civility;
  lastname?: string;
  firstname?: string;
  email?: string;
  phone?: Phone;
  landline?: Phone;
}

const CURRENT_STEP = ProjectStep.CONTACT;

const ProjectContactInformationsPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const { addSnackbar } = useAppContext();
  const theme = useTheme();

  const contextProject = useProjectContext();
  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError) return 'error';
    if (projectQuery.isLoading || !projectQuery.data) return 'loading';
    return 'done';
  }, [projectQuery]);

  const formikRef = useRef<FormikProps<FormContactValues>>(null);

  const getInitialValues = (): FormContactValues => {
    const values: FormContactValues = {};
    if (!projectQuery.data) return values;

    if (projectQuery.data.subscriber) {
      const person = projectQuery.data.subscriber.person;
      return {
        civility: person.civility,
        lastname: person.lastname || '',
        firstname: person.firstname || '',
        email: person.email || '',
        phone: { number: person.phone } as Phone,
        landline: { number: person.landline } as Phone,
      };
    }

    return values;
  };

  const validationSchema = Yup.object().shape({
    civility: Yup.string().nullable().required(messageFieldRequired),
    firstname: Yup.string().nullable().required(messageFieldRequired),
    lastname: Yup.string().nullable().required(messageFieldRequired),
    email: Yup.string().email('Email non valide').nullable(),
    phone: yupFieldPhone.test(
      'at-least-one-property',
      'Au moins un numéro de téléphone doit être renseigné',
      (item, testContext) => {
        return !!(
          (testContext?.parent.landline &&
            formatPhoneRequest(testContext?.parent.landline)) ||
          (item && formatPhoneRequest(item))
        );
      },
    ),
    landline: yupFieldPhone,
  });

  const accessToken = useAccessToken();
  const mutation = useMutation({
    mutationFn: (payload: ProjectStepContact) =>
      updateProjectStepContact(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', id], () => project);
      const nextStep = getNextStep(CURRENT_STEP);
      if (nextStep)
        navigate(`/projets/${id}/${projectStepsData[nextStep]!.path}`);
    },
    onError: (error: Error) => {
      if (error instanceof RestError && error.status === 400) {
        const e = (error.response as ApiResponse<ProjectStepContactPostErrors>)
          .details;
        if (e) onBadRequest(e);
      } else {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      }
    },
  });

  const onBadRequest = (errors: ProjectStepContactPostErrors) => {
    if (errors.subscriber) {
      formikRef.current?.setFieldError('civility', errors.subscriber.civility);
      formikRef.current?.setFieldError(
        'firstname',
        errors.subscriber.firstname,
      );
      formikRef.current?.setFieldError('lastname', errors.subscriber.lastname);
      formikRef.current?.setFieldError('email', errors.subscriber.email);
      formikRef.current?.setFieldError('phone', errors.subscriber.phone);
      formikRef.current?.setFieldError('landline', errors.subscriber.landline);
    }
  };

  const handleSubmit = (values: FormContactValues) => {
    values = emptyStringToUndefined(values);

    mutation.mutate({
      subscriber: {
        ...values,
        phone: values.phone && formatPhoneRequest(values.phone),
        landline: values.landline && formatPhoneRequest(values.landline),
      } as PersonContactRequest,
    });
  };

  const onChangeValues = (values: Person) => {
    values = emptyStringToUndefined(values);
    contextProject.setDataCurrent(prev => {
      if (!prev.project) return prev;
      return {
        ...prev,
        project: {
          ...prev.project,
          subscriber: {
            ...prev.project.subscriber,
            person: {
              ...prev.project.subscriber.person,
              ...values,
            },
          },
        },
      };
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Informations de contact</Typography>
      </Grid>

      {contextStatus === 'done' && projectQuery.data ? (
        <>
          <Grid item xs={12}>
            <Card
              sx={{
                p: 2,
                border: 2,
                borderColor: theme.palette.divider,
              }}
            >
              <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={values => {
                  return handleSubmit(values);
                }}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      <FormikObserver onChange={onChangeValues} />
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ToggleButtonGroupSplit
                              value={values.civility}
                              exclusive
                              onChange={(e, v) => {
                                setFieldValue('civility', v);
                              }}
                            >
                              {Object.entries(Civility).map(([key, value]) => (
                                <ToggleButtonSplit
                                  key={key}
                                  fullWidth
                                  value={key}
                                  color="primary"
                                >
                                  {civilityLabels[key as Civility].label}
                                </ToggleButtonSplit>
                              ))}
                            </ToggleButtonGroupSplit>
                            {touched.civility && errors.civility && (
                              <FormHelperText error>
                                {errors.civility}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              required
                              id="lastname"
                              name="lastname"
                              label="Nom"
                              value={values.lastname}
                              error={
                                touched.lastname && Boolean(errors.lastname)
                              }
                              helperText={
                                touched.lastname &&
                                Boolean(errors.lastname) && (
                                  <>{errors.lastname}</>
                                )
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              required
                              id="firstname"
                              name="firstname"
                              label="Prénom"
                              value={values.firstname}
                              error={
                                touched.firstname && Boolean(errors.firstname)
                              }
                              helperText={
                                touched.firstname &&
                                Boolean(errors.firstname) && (
                                  <>{errors.firstname}</>
                                )
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextFieldPhone
                              label="Téléphone Mobile"
                              value={values.phone?.number}
                              onChange={(value, country) => {
                                setFieldValue('phone', {
                                  number: value,
                                  countryCode: (country as CountryData)
                                    ?.dialCode,
                                });
                              }}
                              error={touched.phone && Boolean(errors.phone)}
                              regions="europe"
                              country="fr"
                              preferredCountries={['fr']}
                              onlyCountries={countryCodeEnabled}
                              countryCodeEditable={false}
                            />
                            {touched.phone && errors.phone && (
                              <FormHelperText error>
                                {errors.phone}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextFieldPhone
                              label="Téléphone Fixe"
                              value={values.landline?.number}
                              onChange={(value, country) => {
                                setFieldValue('landline', {
                                  number: value,
                                  countryCode: (country as CountryData)
                                    ?.dialCode,
                                });
                              }}
                              error={
                                touched.landline && Boolean(errors.landline)
                              }
                              regions="europe"
                              country="fr"
                              preferredCountries={['fr']}
                              onlyCountries={countryCodeEnabled}
                              countryCodeEditable={false}
                            />
                            {touched.landline && errors.landline && (
                              <FormHelperText error>
                                {errors.landline}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              id="email"
                              name="email"
                              label="Email"
                              value={values.email}
                              error={touched.email && Boolean(errors.email)}
                              helperText={
                                touched.email &&
                                Boolean(errors.email) && <>{errors.email}</>
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </Card>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              color="default"
              disabled={mutation.isPending}
              onClick={() => {
                const prevStep = getPreviousStep(CURRENT_STEP);
                if (prevStep)
                  navigate(
                    `/projets/${id}/${projectStepsData[prevStep]!.path}`,
                  );
              }}
              sx={{ px: 4 }}
            >
              Retour
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              disabled={mutation.isPending}
              onClick={() => {
                if (formikRef.current?.values) formikRef.current.handleSubmit();
              }}
              sx={{ px: 4 }}
            >
              {mutation.isPending ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Valider'
              )}
            </Button>
          </Grid>
        </>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="200px" />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectContactInformationsPage;
