import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { getTheme } from '../../../static/themes/theme';
import { useAppContext } from '../../Context/AppContext';

const LayoutContext: React.FC = () => {
  const appContext = useAppContext();

  const theme = getTheme(appContext.data.theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          background: `linear-gradient(0.02deg, ${theme.palette.background.default} 0.02%, ${theme.palette.background.default} 89.34%)`,
        }}
      >
        <Outlet />
      </Box>
    </ThemeProvider>
  );
};

export default LayoutContext;
