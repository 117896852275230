import { queryOptions } from '@tanstack/react-query';
import { Pagination } from 'models/Page.models';
import { useAccessToken } from 'utils/api/api';
import { CommissionSearchCriteriaRequest } from 'models/oav/Commission.models';
import { fetchCommissionList } from 'api/oav/Commission.api';

// 5min
const defaultStaleTime = 5 * 60 * 1000;

export const commissionQueries = {
  searchCommission: (
    pagination: Pagination,
    criteria: CommissionSearchCriteriaRequest,
    sort?: string[],
  ) => commissionSearchCommission(pagination, criteria, sort),
};

export const commissionSearchCommission = (
  pagination: Pagination,
  criteria: CommissionSearchCriteriaRequest,
  sort?: string[],
) => {
  const accessToken = useAccessToken();
  return queryOptions({
    queryKey: [
      'commission',
      'list',
      pagination.page,
      pagination.size,
      criteria,
      sort,
    ],
    queryFn: () => fetchCommissionList(pagination, criteria, accessToken, sort),
    gcTime: defaultStaleTime,
    staleTime: defaultStaleTime,
  });
};
