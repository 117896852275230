import { ReactNode, useState } from 'react';

import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '../../TextField/TextField';

type PopperComponentProps = {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
};

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return (
    <Box
      {...other}
      sx={{
        width: '100% !important',
        '& .MuiAutocomplete-noOptions': {
          p: 1,
        },
        '& .MuiAutocomplete-paper': {
          background: 'none',
        },
      }}
    />
  );
}

export type AutocompleteBoxProps = Partial<
  AutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >
> & {
  hideSearchField?: boolean;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined,
  ) => void;
  renderElement?: (
    option: any,
    state: AutocompleteRenderOptionState,
  ) => ReactNode;
};

const AutocompleteBox = (props: AutocompleteBoxProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <Box>
      <Autocomplete
        {...props}
        open
        options={props.options || []}
        PopperComponent={PopperComponent}
        disableClearable
        disableCloseOnSelect
        inputValue={searchValue}
        PaperComponent={Box}
        onInputChange={(_, value, reason) => {
          if (reason === 'reset') return;
          setSearchValue(value);
        }}
        value={props.value}
        onChange={(event, newValue, reason) => {
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Backspace' &&
            reason === 'removeOption'
          ) {
            return;
          }
          props.onChange(event, newValue, reason);
        }}
        renderTags={() => null}
        renderOption={(propsLi, option, state) => (
          <li
            {...propsLi}
            style={{
              minHeight: 'auto',
              borderRadius: 8,
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                pr: 1,
              }}
            >
              {props.renderElement !== undefined
                ? props.renderElement(option, state)
                : option}
            </Box>
            <Box
              component={CloseIcon}
              sx={{ opacity: 0.6, width: 18, height: 18, mt: '4px' }}
              style={{
                visibility: state.selected ? 'visible' : 'hidden',
              }}
            />
          </li>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            autoFocus
            variant="standard"
            ref={params.inputProps.ref}
            inputProps={params.inputProps}
            InputProps={{ ...params.InputProps, endAdornment: undefined }}
          />
        )}
        sx={{
          display: props.hideSearchField ? 'none' : 'block',
          '&MuiAutocomplete-listbox': {
            pb: 0,
          },
        }}
      />
    </Box>
  );
};

export default AutocompleteBox;
