import { appName } from 'App';
import AppBar from 'components/AppBar';
import React from 'react';
import { WalletCommissionsTable } from 'pages/wallet/commissions/WalletCommissionsTable';
import {
  validationSchema,
  WalletCommissionsFilter,
} from 'pages/wallet/commissions/WalletCommissionsFilter';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { Link } from 'react-router-dom';
import { Link as LinkMUI } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import Stack from '@mui/material/Stack';

const WalletCommissionsPage: React.FC = () => {
  document.title = `Commissions - ${appName}`;

  return (
    <AppBar>
      <Box sx={{ width: '100%' }}>
        <Breadcrumbs
          separator={<ChevronRightIcon size="small" />}
          sx={{ mb: 4 }}
        >
          <Link to="/portefeuille" style={{ color: 'inherit' }}>
            <LinkMUI underline="hover" variant="body2" color="inherit">
              Portefeuille
            </LinkMUI>
          </Link>
          <Typography color="text.primary">Commissions</Typography>
        </Breadcrumbs>
        <Formik
          initialValues={{
            risks: [],
            segments: [],
            startPaymentDate: null,
            endPaymentDate: null,
          }}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          <Stack>
            <WalletCommissionsFilter />
            <WalletCommissionsTable />
          </Stack>
        </Formik>
      </Box>
    </AppBar>
  );
};

export default WalletCommissionsPage;
