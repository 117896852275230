import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

interface FormikObserverProps<T> {
  onChange: (values: T, errors: any) => void;
}

export const FormikObserver = <T,>(props: FormikObserverProps<T>) => {
  const { values, errors } = useFormikContext<T>();
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const oldValues = ref.current;
    ref.current = values;

    // Avoid onChange trigger on first render and if values did not change.
    if (oldValues !== null && !isEqual(oldValues, values)) {
      props.onChange(values, errors);
    }
  }, [values]);
  return null;
};
