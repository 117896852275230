import { IconProps } from './settings';
import { Icon } from '.';

export const NeedRoutineCareIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19" stroke="currentColor" strokeWidth="2" />
        <circle cx="13" cy="16" r="3" fill="currentColor" />
        <circle cx="26" cy="16" r="3" fill="currentColor" />
        <circle cx="33" cy="22" r="2" fill="currentColor" />
        <path
          d="M12 31L12.3677 30.8213C17.3159 28.4159 23.1081 28.4821 28 31V31"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M25 29L33 22"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Icon>
  );
};
