import { RestError } from 'errors/RestError';

import { PageResponse } from 'models/api.model';
import { Pagination } from 'models/Page.models';
import {
  Project,
  ProjectRequestPost,
  ProjectSearchCriteriaRequest,
} from 'models/oav/Project.models';
import { buildParams } from 'utils/api/params';
import { Offer } from 'models/referentiels/Offer.model';
import { formatDate } from 'utils/api/api';
import { Beneficiary } from 'models/oav/Beneficiary.models';
import { Subscription } from 'models/oav/Subscription.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const fetchProjectsList = async (
  accessToken: string,
  pagination?: Pagination,
  criteria?: ProjectSearchCriteriaRequest,
  sort?: string[],
): Promise<PageResponse<Project>> => {
  const params = new URLSearchParams();
  if (pagination)
    buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (criteria) {
    const criteriaParams = {
      ...criteria,
      fromDate: formatDate(criteria.fromDate),
      toDate: formatDate(criteria.toDate),
    };
    buildParams(criteriaParams, 'criterias')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  }

  if (sort) params.append('sort', sort.join(','));
  const url: string = params.size > 0 ? `${baseUrl}?${params}` : baseUrl;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const fetchProjectById = async (
  accessToken: string,
  id: string,
): Promise<Project> => {
  const url = `${baseUrl}/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  const resp = await response.json();

  return {
    ...resp,
    subscriptions: mapSubscriptions(resp.subscriptions),
    subscriber: mapBeneficiary(resp.subscriber),
    partner: resp.partner ? mapBeneficiary(resp.partner) : undefined,
    children: resp.children ? resp.children.map(mapBeneficiary) : undefined,
  };
};

export const mapSubscriptions = (
  subscriptions?: any[],
): Subscription[] | undefined => {
  return subscriptions?.map(_ => ({
    ..._,
    dateStart: _.dateStart && new Date(_.dateStart),
    beneficiaries: _.beneficiaries.map(mapBeneficiary),
  }));
};

export const mapBeneficiary = (beneficiary: any): Beneficiary => ({
  ...beneficiary,
  person: {
    ...beneficiary.person,
    birthdate:
      beneficiary.person.birthdate && new Date(beneficiary.person.birthdate),
  },
});

export const fetchProjectOffers = async (
  accessToken: string,
  id: string,
): Promise<PageResponse<Offer>> => {
  const url = `${baseUrl}/${id}/offers`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const createProject = async (
  accessToken: string,
  payload: ProjectRequestPost,
): Promise<Project> => {
  const url = baseUrl;
  const body = {
    ...payload,
    dateStart: formatDate(payload.dateStart),
    subscriber: {
      ...payload.subscriber,
      birthdate: formatDate(payload.subscriber.birthdate),
    },
    partner: payload.partner
      ? {
          ...payload.partner,
          birthdate: formatDate(payload.partner.birthdate),
        }
      : undefined,
    children: payload.children
      ? payload.children.map(c => ({
          ...c,
          birthdate: formatDate(c.birthdate),
        }))
      : undefined,
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      JSON.stringify(body),
      await response.json(),
    );

  return await response.json();
};
