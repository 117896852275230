import React from 'react';

import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

import { getRiskColor, Risk } from 'models/referentiels/Risk.model';

interface ChipOfferRiskProps {
  risk: Risk;
}

export const ChipOfferRisk: React.FC<ChipOfferRiskProps> = ({ risk }) => {
  const theme = useTheme();
  const color = getRiskColor(risk.code);
  return (
    <Chip
      key={risk.id}
      label={risk.label}
      size="small"
      sx={{
        fontSize: '0.9rem',
        fontWeight: 700,
        color: color
          ? theme.palette.background.default
          : theme.palette.text.primary,
        background: color
          ? `linear-gradient(270deg, ${color.main} 0%, ${color.secondary} 100%)`
          : theme.palette.background.default,
      }}
    />
  );
};
