import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { useDebouncedCallback } from 'use-debounce';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';

import { appName } from 'App';
import AppBar from 'components/AppBar';
import { usePersonContext } from 'components/Context/PersonContext';
import { TableRowLink } from 'components/Table/TableRowLink';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import { LoadingTableRows } from 'components/Table/LoadingTableRows';
import { TablePagination } from 'components/Table/TablePagination';
import { PersonSearchForm } from 'pages/person/PersonSearchForm';
import { PersonSearchCriteriaRequest } from 'models/oav/Person.models';
import { Pagination } from 'models/Page.models';
import { TableLinearLoading } from 'components/Table/TableLinearLoading';
import { personGetListOptions } from 'api/oav/PersonQuery.api';

const removeFieldsInError = (
  values: PersonSearchCriteriaRequest,
  errors: any,
): PersonSearchCriteriaRequest => {
  return {
    birthdate: errors.birthdate ? null : values.birthdate,
    firstname: errors.firstname ? '' : values.firstname,
    email: errors.email ? '' : values.email,
    lastname: errors.lastname ? '' : values.lastname,
  };
};

const PersonIndexPage: React.FC = () => {
  document.title = `Personnes - ${appName}`;

  const {
    setSelectedTab,
    pagination,
    setPagination,
    searchCriteria,
    setSearchCriteria,
  } = usePersonContext();

  useEffect(() => setSelectedTab('identity'), []);

  const [paginationToDisplay, setPaginationToDisplay] =
    useState<Pagination>(pagination);

  const { initSearchCriteriaNoErrors, initialSearchCriteria } = useMemo(() => {
    return {
      initSearchCriteriaNoErrors: removeFieldsInError(
        searchCriteria.value,
        searchCriteria.errors,
      ),
      initialSearchCriteria: searchCriteria.value,
    };
  }, []);

  const [searchCriteriaNoErrors, setSearchCriteriaNoErrors] = useState(
    initSearchCriteriaNoErrors,
  );

  const debounceContextRef = useRef({
    values: initSearchCriteriaNoErrors,
    fetchByDebounce: false,
  });
  const debounce = useDebouncedCallback(
    (values: PersonSearchCriteriaRequest) => {
      if (!isEqual(debounceContextRef.current.values, values)) {
        debounceContextRef.current.values = values;
        debounceContextRef.current.fetchByDebounce = true;
        setPagination(prevState => ({ ...prevState, page: 0 }));
        setSearchCriteriaNoErrors(values);
      }
    },
    500,
  );

  const { data, error, isFetching } = useQuery(
    personGetListOptions(pagination, searchCriteriaNoErrors),
  );

  const errorComponent = error ? (
    <ErrorMessage message="Une erreur est survenu lors de la récupération des personnes." />
  ) : undefined;

  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPaginationToDisplay(p => ({ ...p, size: parseInt(e.target.value) }));
    setPagination(p => ({ ...p, size: parseInt(e.target.value) }));
  };
  const handlePageChange = (e: unknown, newPage: number) => {
    setPaginationToDisplay(p => ({ ...p, page: newPage }));
    setPagination(p => ({ ...p, page: newPage }));
  };

  const handleChangeSearchForm = useCallback(
    (values: PersonSearchCriteriaRequest, errors: any) => {
      setSearchCriteria({ value: values, errors });
      debounce(removeFieldsInError(values, errors));
    },
    [],
  );

  const loading = data === undefined;

  useEffect(() => {
    if (
      debounceContextRef.current.fetchByDebounce &&
      !debounce.isPending() &&
      !isFetching
    ) {
      debounceContextRef.current.fetchByDebounce = false;
      setPaginationToDisplay(prev => ({ ...prev, page: 0 }));
    }
  }, [isFetching]);

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="h2">Personnes</Typography>
          <Link to="nouveau">
            <Button size="medium" variant="gradient">
              Nouvelle
            </Button>
          </Link>
        </Stack>
      </AppBar>
      <PersonSearchForm
        initialCriteria={initialSearchCriteria}
        onChange={handleChangeSearchForm}
      />
      <Box sx={{ width: '100%', pt: 4 }}>
        {errorComponent ?? (
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ position: 'relative' }}>
                  <TableCell width="25%">
                    {isFetching && <TableLinearLoading />}
                    Prénom
                  </TableCell>
                  <TableCell width="25%">Nom</TableCell>
                  <TableCell width="18%">Date de naissance</TableCell>
                  <TableCell width="32%">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <LoadingTableRows rowSize={pagination.size!} cellSize={5} />
                ) : (
                  data.persons.map((person, idx) => {
                    const to = `/personnes/${person.id}`;
                    return (
                      <TableRowLink to={to} key={`entry-${idx}`}>
                        <TableCell
                          sx={{
                            textOverflow: 'ellipsis',
                            borderTopLeftRadius: '1.5rem',
                            borderBottomLeftRadius: '1.5rem',
                          }}
                        >
                          {person.firstname ? person.firstname : undefined}
                        </TableCell>
                        <TableCell sx={{ textOverflow: 'ellipsis' }}>
                          {person.lastname ? person.lastname : undefined}
                        </TableCell>
                        <TableCell sx={{ textOverflow: 'ellipsis' }}>
                          {person.birthdate
                            ? dayjs(person.birthdate).format('DD/MM/YYYY')
                            : undefined}
                        </TableCell>
                        <TableCell
                          sx={{
                            textOverflow: 'ellipsis',
                            borderTopRightRadius: '1.5rem',
                            borderBottomRightRadius: '1.5rem',
                          }}
                        >
                          {person.email ? person.email : undefined}
                        </TableCell>
                      </TableRowLink>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={3}
                    rowsPerPageOptions={[10, 25, 50]}
                    count={data?.total ?? 0}
                    rowsPerPage={paginationToDisplay.size!}
                    page={paginationToDisplay.page!}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default PersonIndexPage;
